import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import { useNavigate, useParams } from 'react-router-dom'
import Pagination from '../../components/Pagination'
import instance from '../../services/apiServices/Api'
import Storage from '../../services/local_storage/localStorage';
import SimpleBackdrop from '../../components/Backdrop'
import { errorMessage, successMessage } from '../../helpers/Message'
import NoDataFound from '../../components/NoDataFound'
import { AppTexts } from '../../constants/AppTexts'


function ReleaseForm() {
    const navigate = useNavigate()
    const page = useParams()
    const token = Storage.getToken("token")
    const header = { headers: { Authorization: `Bearer ${token}` } }
    const [releaseFormData, setReleaseFormData] = useState({})
    const [itemsPerPage, setItemsPerPages] = useState()
    const [totalItems, setTotalItems] = useState()
    const currentPage = Object.values(page)[0].startsWith('current_page') ? Object.values(page)[0] : "current_page=1"
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getreleaseForm(currentPage)
        // eslint-disable-next-line
    }, [currentPage])


    const getreleaseForm = () => {
        setLoading(true)
        instance.post(`/contributor/releases`, { current_page: currentPage.slice(13) }, header).then((res) => {
            setLoading(false)
            setReleaseFormData(res.data.data.items)
            setItemsPerPages(res.data.data.perPage)
            setTotalItems(res.data.data.totalRecords)
        }).catch((err) => {
            setLoading(false)
            errorMessage('Error', err.response.data.message)
        })

    }

    const deleteForm = (e, id) => {
        e.preventDefault()
        setLoading(true)
        instance.post(`/contributor/releases/delete`, { release_id: id }, header).then((res) => {
            setLoading(false)
            successMessage("Success", "Release Form deleted successfuly")
            getreleaseForm()
        }).catch((err) => {
            setLoading(false)
            errorMessage('Error', err.response.data.message)
        })
    }

    return (
        <>
            {
                loading && <SimpleBackdrop />
            }
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="dashboard-title">Release Form</h2>
                <button className='btn primary-btn' onClick={() => navigate('create', { state: { title: "Create" } })} >Create</button>
            </div>
            <div className="main purchase-history-wrap">
                {releaseFormData.length > 0 ?
                    <>
                        <div className='purchase-history result-table'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Release Id</th>
                                        <th>Title</th>
                                        <th>Created Date</th>
                                        <th>Status</th>
                                        <th>Associated Count</th>
                                        <th>Associated Sold Count</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        releaseFormData.length > 0 && releaseFormData?.map((item, i) =>
                                            <>
                                                <tr key={i}>
                                                    <td>{item?.id}</td>
                                                    <td>{item?.name}</td>
                                                    <td>{moment(item?.created_at).format("MMMM Do YYYY")}</td>
                                                    <td>{item?.is_expired ? "Expired" : "Active"}</td>
                                                    <td>{item?.download_associated_count}</td>
                                                    <td>{item?.download_associated_sold_count}</td>
                                                    <td>
                                                        <div className="release-action" aria-disabled={item.is_expired}>
                                                            <span data-tooltip-id="my-tooltip" data-tooltip-content="View" className='release-view' onClick={() => navigate(`detail/${item.id}`)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM432 256c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zM288 192c0 35.3-28.7 64-64 64c-11.5 0-22.3-3-31.6-8.4c-.2 2.8-.4 5.5-.4 8.4c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-2.8 0-5.6 .1-8.4 .4c5.3 9.3 8.4 20.1 8.4 31.6z" /></svg>
                                                            </span>
                                                            <span className='release-edit' data-tooltip-id="my-tooltip" data-tooltip-content='Edit' onClick={() => navigate(`edit/${item.id}`)} >
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V285.7l-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" /></svg>
                                                            </span>

                                                            <span className='release-delete' data-tooltip-id="my-tooltip" data-tooltip-content='Delete' onClick={(e) => deleteForm(e, item.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                </tbody>
                            </table>
                        </div>
                        <Pagination itemsPerPage={itemsPerPage} TotalItems={totalItems} FocusPage={currentPage.slice(13)} />
                    </>
                    : loading ? <h3 className='nodDtaFound'>{AppTexts.Loading}.</h3> : <NoDataFound title={`No release form found`} />}
            </div>
        </>
    )
}

export default ReleaseForm