import React from 'react'
import logo from "../assets/logo.png"

function Fallback() {
  return (
    <div>
      <img className='fallback' src={logo} alt='error' />
    </div>
  )
}

export default Fallback