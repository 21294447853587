import React, { useEffect, useState } from "react";
import instance from "../../services/apiServices/Api";
import Storage from "../../services/local_storage/localStorage";
import img from "../../assets/imagePlaceholder.jpg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { errorMessage } from "../../helpers/Message";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound";
import { AppTexts } from "../../constants/AppTexts";



function Galllery() {
  const token = Storage.getToken("token");
  const header = { headers: { Authorization: `Bearer ${token}` } };
  const [data, setData] = useState([]);
  const [dataPublish, setDataPublish] = useState([]);
  const [dataPending, setDataPending] = useState([]);
  const [dataDraft, setDataDraft] = useState([]);
  const [draftCount, setDraftCount] = useState("");
  const [dataInherit, setDataInherit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState("image");
  const [sortBy, setSortBy] = useState("newness_desc");
  const [status, setStatus] = useState("");
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    getData(sortBy, fileType, status);
    // eslint-disable-next-line
  }, []);

  const getData = (sortBy, fileType, status) => {
    setLoading(true);
    setStatus(status);
    let data = {
      current_page: 1 ?? 1,
      per_page: 100,
      order_by: sortBy,
      type: fileType,
      status: status,
    };
    instance
      .post("/contributor/products", data, header)
      .then((res) => {
        let common = res.data?.data?.items;
        setLoading(false);
        setData(common);
        const pendingArr = [];
        const publishArr = [];
        const draftArr = [];
        const inheritArr = [];

        setDraftCount(res.data.data.draft_count);

        common.forEach((item) => {
          if (status === "publish") {
            publishArr.push(item);
          }
          if (status === "pending") {
            pendingArr.push(item);
          }
          if (status === "draft") {
            draftArr.push(item);
          }
          if (status === "most_liked") {
            inheritArr.push(item);
          }
        });

        setDataPending(pendingArr);
        setDataPublish(publishArr);
        setDataDraft(draftArr);
        setDataInherit(inheritArr);
      })
      .catch((err) => {
        setLoading(false);
        errorMessage("Error", err.response.data.message);
      });
  };

  const handleFileTypeData = (value) => {
    setFileType(value);
    getData(sortBy, value, status);
  };

  const handleSortData = (value) => {
    setSortBy(value);
    getData(value, fileType, status);
  };

  const ToggleDropdowns = (value) => {
    if (value === "open-filter-variation") {
      document
        .getElementById("open-filter-variation")
        .classList.toggle("open-filter-variation");
      document
        .getElementById("open-filter-variation2")
        .classList.remove("open-filter-variation");
    } else {
      document
        .getElementById("open-filter-variation2")
        .classList.toggle("open-filter-variation");
      document
        .getElementById("open-filter-variation")
        .classList.remove("open-filter-variation");
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div>
      <h2 className="dashboard-title">Gallery</h2>
      <div className="dashboard-tabs-outer">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="All"
                {...a11yProps(0)}
                onClick={() => getData(sortBy, fileType, "")}
              />
              <Tab
                label="Live"
                {...a11yProps(1)}
                onClick={() => getData(sortBy, fileType, "publish")}
              />
              <Tab
                label="Pending Review"
                {...a11yProps(2)}
                onClick={() => getData(sortBy, fileType, "pending")}
              />
              <Tab
                label={
                  <span>
                    New<span>{draftCount}</span>
                  </span>
                }
                {...a11yProps(3)}
                onClick={() => getData(sortBy, fileType, "draft")}
              />
              <Tab
                label="Insight"
                {...a11yProps(4)}
                onClick={() => getData(sortBy, fileType, "most_liked")}
              />
            </Tabs>
          </Box>
          <div className="tabcontent-filter">
            <div className="field-outer search-variation-outer tabcontent-inner">
              <label>Media Type :</label>
              <div
                id="open-filter-variation"
                className="search-variation"
                onClick={() => ToggleDropdowns("open-filter-variation")}
              >
                <div className="inputField">
                  {fileType ? fileType : "Select"}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 
                                                    0-45.3s-32.8-12.5-45.3 0L224 306.7 
                                                    86.6 169.4c-12.5-12.5-32.8-12.5-45.3 
                                                    0s-12.5 32.8 0 45.3l160 160z"
                    />
                  </svg>
                </div>
                <div className="search-variation-submenu ">
                  <ul className="search-variation-list">
                    <li onClick={() => handleFileTypeData("image")}>Image</li>
                    <li onClick={() => handleFileTypeData("video")}>Video</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="field-outer search-variation-outer tabcontent-inner">
              <label>Sort by: </label>
              <div
                id="open-filter-variation2"
                className="search-variation"
                onClick={() => ToggleDropdowns("open-filter-variation2")}
              >
                <div className="inputField">
                  {sortBy
                    ? sortBy === "newness_desc"
                      ? "Descending"
                      : "Ascending"
                    : "Select"}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      d="M201.4 374.6c12.5 12.5 32.8 12.5 
                                                    45.3 0l160-160c12.5-12.5 12.5-32.8 
                                                    0-45.3s-32.8-12.5-45.3 0L224 306.7 
                                                    86.6 169.4c-12.5-12.5-32.8-12.5-45.3 
                                                    0s-12.5 32.8 0 45.3l160 160z"
                    />
                  </svg>
                </div>
                <div className="search-variation-submenu ">
                  <ul className="search-variation-list">
                    {/* Descending:newness_desc,Ascending:newness_asc */}
                    <li onClick={() => handleSortData("newness_desc")}>
                      Descending
                    </li>
                    <li onClick={() => handleSortData("newness_asc")}>
                      Ascending
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <TabPanel value={value} index={0}>
            <div className="tabcontent-wrap">
              <div className="tabcontent-content">
                <div className="tabcontent-row">
                  {data?.length > 0 ? (
                    data?.map((item, index) => {
                      return (
                        <div
                          id={item.id}
                          key={item.id}
                          className="tabcontent-column "
                        >
                          <div className="tabcontentcolumn-inner">
                            <img
                              loading="lazy"
                              src={
                                item?.watermarked_file?.file
                                  ? item?.watermarked_file?.file
                                  : img
                              }
                              alt="Loading . . . . ."
                              />
                            <div
                              className={`tabcontentcolumn-info ${
                                item.status === 0
                                  ? "pending"
                                  : item.status === 1
                                  ? "live"
                                  : item.status === 2 && "draft"
                              }`}
                            >
                              <p>
                                Downloads{" "}
                                <span className="info-count">
                                  {item?.total_sale_count}
                                </span>
                              </p>
                              <p>
                                Likes{" "}
                                <span className="info-count">
                                  {item?.like_count ? item?.like_count : 0}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="noRecord mt-50">
                      {loading ? (
                        <h3 className="nodDtaFound">{AppTexts.Loading}.</h3>
                      ) : (
                        <NoDataFound title={`No record found`} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </TabPanel>

          {/******Live Tab*******/}
          <TabPanel value={value} index={1}>
            <div className="tabcontent-wrap">
              <div className="tabcontent-content">
                <div className="tabcontent-row">
                  {dataPublish?.length > 0 ? (
                    dataPublish?.map((item, index) => {
                      return (
                        <div
                          id={item.id}
                          key={item.id}
                          className="tabcontent-column "
                        >
                          <div className="tabcontentcolumn-inner">
                            <img
                              loading="lazy"
                              src={
                                item?.watermarked_file?.file
                                  ? item?.watermarked_file?.file
                                  : img
                              }
                              alt="Loading . . . . ."
                              />
                            <div className="tabcontentcolumn-info live">
                              <p>
                                Downloads{" "}
                                <span className="info-count">
                                  {item?.total_sale_count}
                                </span>
                              </p>
                              <p>
                                Likes{" "}
                                <span className="info-count">
                                  {item?.like_count ? item?.like_count : 0}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="noRecord">
                      {loading ? (
                        <h3 className="nodDtaFound">{AppTexts.Loading}.</h3>
                      ) : (
                        <NoDataFound title={`No live download  record found`} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </TabPanel>

          {/******Pending Tab*******/}
          <TabPanel value={value} index={2}>
            <div className="tabcontent-wrap">
              <div className="tabcontent-content">
                <div className="tabcontent-row">
                  {dataPending?.length > 0 ? (
                    dataPending?.map((item, index) => {
                      return (
                        <div
                          id={item.id}
                          key={item.id}
                          className="tabcontent-column "
                        >
                          <div className="tabcontentcolumn-inner">
                            <button
                              className="btn primary-btn galleryEditbtn"
                              onClick={() =>
                                navigate(`edit/${item.id}`, {
                                  state: {
                                    submit_for_approval: 1,
                                    type: fileType,
                                  },
                                })
                              }
                            >
                              Edit
                            </button>
                            <img
                              loading="lazy"
                              src={
                                item?.watermarked_file?.file
                                  ? item?.watermarked_file?.file
                                  : img
                              }
                              alt="Loading . . . . ."
                              />
                            <div className="tabcontentcolumn-info pending">
                              <p>
                                Downloads{" "}
                                <span className="info-count">
                                  {item?.total_sale_count}
                                </span>
                              </p>
                              <p>
                                Likes{" "}
                                <span className="info-count">
                                  {item?.like_count ? item?.like_count : 0}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="noRecord">
                      {loading ? (
                        <h3 className="nodDtaFound">{AppTexts.Loading}.</h3>
                      ) : (
                        <NoDataFound
                          title={`No Pending download record found`}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </TabPanel>

          {/******New Tab*******/}
          <TabPanel value={value} index={3}>
            <div className="tabcontent-wrap">
              <div className="tabcontent-content">
                <div className="tabcontent-row">
                  {dataDraft?.length > 0 ? (
                    dataDraft?.map((item, index) => {
                      return (
                        <div
                          id={item.id}
                          key={item.id}
                          className="tabcontent-column "
                        >
                          <div className="tabcontentcolumn-inner">
                            <button
                              className="btn primary-btn galleryEditbtn "
                              onClick={() =>
                                navigate(`edit/${item.id}`, {
                                  state: {
                                    submit_for_approval: "0",
                                    type: fileType,
                                  },
                                })
                              }
                            >
                              Edit
                            </button>
                            <img
                              loading="lazy"
                              src={
                                item?.watermarked_file?.file
                                  ? item?.watermarked_file?.file
                                  : img
                              }
                              alt="Loading . . . . ."
                              />
                            <div className="tabcontentcolumn-info draft">
                              <p>
                                Downloads{" "}
                                <span className="info-count">
                                  {item?.total_sale_count}
                                </span>
                              </p>
                              <p>
                                Likes{" "}
                                <span className="info-count">
                                  {item?.like_count ? item?.like_count : 0}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="noRecord">
                      {loading ? (
                        <h3 className="nodDtaFound">{AppTexts.Loading}.</h3>
                      ) : (
                        <NoDataFound title={`No new download record found`} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </TabPanel>

          {/******Insight Tab*******/}
          <TabPanel value={value} index={4}>
            <div className="tabcontent-wrap">
              <div className="tabcontent-content">
                <div className="tabcontent-row">
                  {dataInherit?.length > 0 ? (
                    dataInherit?.map((item, index) => {
                      return (
                        <div
                          id={item.id}
                          key={item.id}
                          className="tabcontent-column "
                        >
                          <div className="tabcontentcolumn-inner">
                            <img
                              loading="lazy"
                              src={
                                item?.watermarked_file?.file
                                  ? item?.watermarked_file?.file
                                  : img
                              }
                              alt="Loading . . . . ."
                              />
                            <div className="tabcontentcolumn-info live">
                              <p>
                                Downloads{" "}
                                <span className="info-count">
                                  {item?.total_sale_count}
                                </span>
                              </p>
                              <p>
                                Likes{" "}
                                <span className="info-count">
                                  {item?.like_count ? item?.like_count : 0}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="noRecord">
                      {loading ? (
                        <h3 className="nodDtaFound">{AppTexts.Loading}.</h3>
                      ) : (
                        <NoDataFound
                          title={`No insight download record found`}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}

export default Galllery;
