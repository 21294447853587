import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import SimpleBackdrop from "../../components/Backdrop";
import { errorMessage, successMessage } from "../../helpers/Message";
import instance from "../../services/apiServices/Api";
import Storage from "../../services/local_storage/localStorage";
import * as Yup from "yup";
// import { Autocomplete, TextField } from "@mui/material";
import CustomizedHook from "../../components/customHooks/CustomizedHook";

function GalleryEditDownload() {
  const { id } = useParams();
  const { state } = useLocation();
  const token = Storage.getToken("token");
  const header = { headers: { Authorization: `Bearer ${token}` } };
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [releaseList, setReleaseList] = useState([]);
  const [releaseInfo, setReleaseInfo] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getData(id);
    // eslint-disable-next-line
  }, []);
  const getData = (id) => {
    setLoading(true);
    instance
      .post(
        "/contributor/downloads",
        { download_id: id, type: state.type },
        header
      )
      .then((res) => {
        setLoading(false);
        setReleaseList(res.data.releases);
        for (let item in formik.initialValues) {
          if ([item] in res.data.data) {
            formik.setFieldValue(
              item,
              res.data.data[item] === null ? "" : res.data.data[item]
            );
          }
          if (["is_recognized"].includes(item)) {
            formik.setFieldValue(
              "is_recognized",
              res.data.data.is_recognized ? "1" : "0"
            );
          }
        }

        formik.setFieldValue(
          "release_id",
          res.data.data.release.length <= 0
            ? []
            : res.data.data.release.map((item, i) => item.release_id)
        );
        setSelected(res.data.data.tags.map((item) => item.name));
        setReleaseInfo(res.data.data.release.map((item) => item.release_info));
      })
      .catch((err) => {
        setLoading(false);
        errorMessage("Error", err.response.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      download_id: [id],
      title: "",
      description: "",
      media_type: "creative",
      is_recognized: "0",
      submit_for_approval: state.submit_for_approval,
      release_id: [],
      tags: [],
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Title is Required")
        .max(255, "Title must be less then 255 characters"),
      description: Yup.string()
        .required("Description is Required")
        .max(250, "description must be less then 250 characters"),
      media_type: Yup.string().required("Media type is Required"),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      if (selected.length >= 6) {
        let data = { ...values, tags: selected };
        instance
          .post("contributor/downloads/update", data, token)
          .then((res) => {
            setLoading(false);
            successMessage("Success", "Updated Successfully").then(
              navigate("/contributor-dashboard/gallery")
            );
            resetForm();
            setSelected([]);
          })
          .catch((error) => {
            setLoading(false);
            errorMessage("Error", "Something went wrong");
          });
      } else {  
        setLoading(false);
        errorMessage("Error", "Required minimum 6 keywords");
      }
    },
  });
  // const updateData = (e) => {
  //     e.preventDefault()
  //     setLoading(true)
  //     const value = {
  //         download_id: id,
  //         title: data?.title,
  //         description: data?.description,
  //         media_type: data?.media_type,
  //         is_recognized: data?.is_recognized,
  //         release_id: data?.release_id,
  //         submit_for_approval: state,
  //         tags: selected,
  //     }
  //     if (data?.is_recognized === 1) {
  //         return ({ ...value, file_url: data?.file_url })
  //     }

  //     instance.post("contributor/downloads/update", value, token).then(res => {
  //         setLoading(false)
  //         if (res.status === 200) {
  //             successMessage("Success", "Updated Successfully").then(() => navigate('/contributor-dashboard/gallery'));
  //         }
  //         else {
  //             errorMessage("Error", "Somthing went wrong")
  //         }
  //     }).catch((err) => {
  //         setLoading(false)
  //         errorMessage("Error", err.response.data.message)
  //     })
  // }
  const multiSearch = (newInputValue) => {
    const release_ids = newInputValue.map((re) => re.id);
    formik.setFieldValue("release_id", release_ids);
  };
  return (
    <>
      {loading && <SimpleBackdrop />}
      <h2 className="dashboard-title">Edit Download Details</h2>
      <form onSubmit={formik.handleSubmit} className="">
        <div className="field-outer">
          <label>Title</label>
          <input
            className="inputField"
            name="title"
            type="text"
            placeholder="Enter Title"
            {...formik.getFieldProps("title")}
            value={formik.values.title ?? ""}
          />
          {formik.errors.title && formik.touched.title ? (
            <div className="validation-error">{formik.errors.title}</div>
          ) : null}
        </div>
        <div className="field-outer">
          <label>Keyword (Use Comma or enter for next tag)</label>
          <TagsInput
            value={selected}
            onChange={setSelected}
            name="tags"
            separators={["Enter", ","]}
            placeHolder="Enter Keyword"
          />
        </div>
        <div className="field-outer">
          <label>Description</label>
          <textarea
            placeholder="Enter Description"
            className="inputField"
            name="description"
            {...formik.getFieldProps("description")}
          ></textarea>
          {formik.errors.description && formik.touched.description ? (
            <div className="validation-error">{formik.errors.description}</div>
          ) : null}
        </div>
        <div className="field-outer field-outer-radio-wrap justify-content-start ">
          <label>Select media type:</label>
          <div className="radio-wrap ms-20">
            <input
              name="media_type"
              type="radio"
              value="editorial"
              checked={formik.values.media_type === "editorial"}
              onChange={(e) =>
                formik.setFieldValue("media_type", e.target.value)
              }
            />
            <label>Editorial</label>
          </div>

          <div className="radio-wrap ms-20">
            <input
              name="media_type"
              type="radio"
              value="creative"
              checked={formik.values.media_type === "creative"}
              onChange={(e) =>
                formik.setFieldValue("media_type", e.target.value)
              }
            />
            <label>Creative</label>
          </div>
          {formik.errors.media_type && formik.touched.media_type ? (
            <div className="validation-error">{formik.errors.media_type}</div>
          ) : null}
        </div>
        <div className="field-outer field-outer-radio-wrap justify-content-start">
          <label>Recognizable people or property?</label>
          <div className="radio-wrap ms-20">
            <input
              name="is_recognized"
              type="radio"
              value="1"
              checked={formik.values.is_recognized > 0 ? true : false}
              onChange={(e) => {
                formik.setFieldValue("is_recognized", e.target.value);
                formik.setFieldValue("release_id", []);
              }}
            />
            <label>Yes</label>
          </div>

          <div className="radio-wrap ms-20">
            <input
              name="is_recognized"
              type="radio"
              value="0"
              checked={formik.values.is_recognized === "0"}
              onChange={(e) => {
                formik.setFieldValue("is_recognized", e.target.value);
                formik.setFieldValue("release_id", []);
                setReleaseInfo([]);
              }}
            />
            <label>No</label>
          </div>
        </div>
        <div
          className={
            formik.values.is_recognized.toString() === "0"
              ? "field-outer field-outerclose"
              : "field-outer field-outeropen"
          }
          hidden={releaseList?.length <= 0 ? true : false}
        >
          {releaseInfo.length > 0 || formik.values.is_recognized >= 1 ? (
            <CustomizedHook
              dataList={releaseList}
              setData={multiSearch}
              selectedList={releaseInfo}
            />
          ) : // <Autocomplete
          //     // id="country-select-demo"
          //     className=''
          //     options={releaseList}
          //     sx={{ width: 300 }}
          //     autoHighlight
          //     onChange={multiSearch}
          //     defaultValue={{ id: releaseInfo?.id ?? "", name: releaseInfo?.name ?? "" }}
          //     getOptionLabel={(option) => option.name}
          //     renderInput={(params) =>
          //         <TextField
          //             className='inputField'
          //             placeholder='Select Release Form'
          //             {...params}

          //         />}
          // />
          null}
        </div>
        <div
          className={
            formik.values.is_recognized.toString() === "0"
              ? "field-outer field-outerclose"
              : "field-outer field-outeropen"
          }
          hidden={formik.values.release_id.length > 0}
        >
          <p hidden={releaseList?.length <= 0}>Or</p>
          <p className="mt-3">Create a new release</p>
          <Link
            className="btn primary-btn btn-medium mt-3"
            to="/contributor-dashboard/release-form/create"
            state={{ url: "/contributor-dashboard/listing", type: state?.type }}
          >
            Create
          </Link>
        </div>

        <div className="field-outer field-outer-action-wrap ">
          <button type="submit" className="btn primary-btn btn-large">
            Save work
          </button>
        </div>
      </form>
    </>
  );
}

export default GalleryEditDownload;
