import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SimpleBackdrop from '../../components/Backdrop'
import NoDataFound from '../../components/NoDataFound'
import Pagination from '../../components/Pagination'
import { errorMessage } from '../../helpers/Message'
import instance from '../../services/apiServices/Api'
import Storage from '../../services/local_storage/localStorage';
import img from '../../assets/imagePlaceholder.jpg'
import { AppTexts } from '../../constants/AppTexts'



function Earnings() {
  const page = useParams()
  const token = Storage.getToken("token")
  const header = { headers: { Authorization: `Bearer ${token}` } }
  const [earningData, setEarningData] = useState({})
  const [itemsPerPage, setItemsPerPages] = useState()
  const [totalItems, setTotalItems] = useState()
  const currentPage = Object.values(page)[0].startsWith('current_page') ? Object.values(page)[0] : "current_page=1"
  let [loading, setLoading] = useState(false);


  useEffect(() => {
    getEarnings(currentPage)
    // eslint-disable-next-line
  }, [currentPage])
  const getEarnings = () => {
    setLoading(true)
    instance.post(`/contributor/commission`, { current_page: currentPage.slice(13) }, header).then((res) => {
      setLoading(false)
      setEarningData(res.data.data.items)
      setItemsPerPages(res.data.data.perPage)
      setTotalItems(res.data.data.totalRecords)
    }).catch((err) => {
      setLoading(false)
      errorMessage("Error", err.response.data.message)
    })
  }



  return (
    <>
      {
        loading && <SimpleBackdrop />
      }
      <h2 className="dashboard-title">Earnings</h2>
      <div className="main purchase-history-wrap">
        {earningData.length > 0 ?
          <>
            <div className='purchase-history result-table'>
              <table>
                <thead>
                  <tr>
                    <th>Order id</th>
                    <th>Thumbnail</th>
                    <th>Download Title</th>
                    <th>Status</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    earningData.length > 0 && earningData?.map((item, i) =>
                      <>
                        <tr key={item.order_id}>
                          <td>{item?.order_id}</td>
                          <td><img src={item?.download?.watermarked_file?.file ?? img} alt="thumbnail" height="70" width="70" style={{objectFit:'cover'}} /> </td>
                          <td>{item?.download?.title}-{item?.download_id}</td>
                          <td>{item?.status === 1 ? "Paid" : "Unpaid"}</td>
                          <td>{item?.amount > 0 ? "$" + item?.amount : "Free"}</td>
                        </tr>
                      </>
                    )}
                </tbody>
              </table>
            </div>
            <Pagination itemsPerPage={itemsPerPage} TotalItems={totalItems} FocusPage={currentPage.slice(13)} />
          </>
          : loading ? <h3 className='nodDtaFound'>{AppTexts.Loading}.</h3> : <NoDataFound title='No earning record found' />}
      </div>
    </>
  )
}

export default Earnings