import React from 'react'
import { NavLink } from 'react-router-dom'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import bannerImg from '../assets/aboutBanner.png';

function TitleBanner({ item }) {

    return (
        <>
            <section className="aboutUs-banner" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
                <div className="banner--main-inside">
                    <h1>{item.title}</h1>
                    <p className="breadcrumbs-outer"><NavLink to={item.link}>Home</NavLink>
                        <span className="breadcrumb-arrow">
                            <FontAwesomeIcon className="fa-angle-right" icon={faAngleRight} />
                        </span>
                        <NavLink to={item?.mainLink ?? "#"}>{item.breadCrumbs_main}</NavLink>
                        {item?.breadCrumbs_submain &&
                            <>
                                <span className="breadcrumb-arrow">
                                    <FontAwesomeIcon className="fa-angle-right" icon={faAngleRight} />
                                </span>
                                <NavLink to={item?.subMainLink ?? "#"}>{item.breadCrumbs_submain}</NavLink>
                            </>
                        }
                    </p>
                </div>
            </section>
        </>
    )
}

export default TitleBanner