import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SimpleBackdrop from '../../components/Backdrop';
import { errorMessage } from '../../helpers/Message';
import instance from '../../services/apiServices/Api';
import Storage from '../../services/local_storage/localStorage';

function OrderDetail() {
    const { state } = useLocation();
    const token = Storage.getToken("token")
    const header = { headers: { Authorization: `Bearer ${token}` } }
    const [orderDetail, setOrderDetail] = useState({})
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getOrderDetail()
        // eslint-disable-next-line
    }, [])

    const getOrderDetail = () => {
        setLoading(true)
        instance.post(`/contributor/downloads/orders`, { id: state.order_id }, header).then((res) => {
            setOrderDetail(res.data.data)
            setLoading(false)
        }).catch((err) => {
            errorMessage('Error', err.response.data.message)
            setLoading(false)
        })
    }
    return (
        <>
            {
                loading && <SimpleBackdrop />
            }
            <h2 className="dashboard-title">Order Detail</h2>

            <div className="thankyou-page tbl-main ">
                <div className="tble-clm">
                    <h2 className="section-heading">Order INFORMATION</h2>
                    <table >
                        <thead>
                            <tr>
                                <th>Orders</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Download Id</td>
                                <td>{orderDetail?.download_id}</td>
                            </tr>
                            <tr>
                                <td>Download Title</td>
                                <td>{orderDetail?.title}</td>
                            </tr>
                            <tr>
                                <td>Order Id</td>
                                <td>#{orderDetail?.order_id}</td>
                            </tr>
                            <tr>
                                <td>Ordered By</td>
                                <td>{orderDetail?.order?.first_name} {orderDetail?.order?.last_name}</td>
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <td>{orderDetail?.price>0? `$ ${orderDetail.price}`:"Free"}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default OrderDetail