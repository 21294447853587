import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleBackdrop from '../../components/Backdrop';
import Pagination from '../../components/Pagination';
import { errorMessage } from '../../helpers/Message';
import instance from '../../services/apiServices/Api';
import Storage from '../../services/local_storage/localStorage';
import userLogo from "../../assets/user-logo.png";
import NoDataFound from '../../components/NoDataFound';
import { AppTexts } from '../../constants/AppTexts';



function FollowDetail() {
  const page = useParams()
  const userData = Storage.getUserData();
  const token = Storage.getToken("token")
  const header = { headers: { Authorization: `Bearer ${token}` } }
  const [followData, setFollowData] = useState({})
  const [itemsPerPage, setItemsPerPages] = useState()
  const [totalItems, setTotalItems] = useState()
  const [selectedList, setSelectedList] = useState(userData.role_label === "User"?"following":"follower")
  const currentPage = Object.values(page)[0].startsWith('current_page') ? Object.values(page)[0] : "current_page=1"
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData.role_label === "User") {
      getFollowDetails(currentPage, "following")

    } else {
      getFollowDetails(currentPage, selectedList)
    }
    // eslint-disable-next-line
  }, [currentPage, selectedList])

  const getFollowDetails = (currentPage, selectedList) => {
    setLoading(true)
    instance.post(`/follow-details`, { current_page: currentPage.slice(13), list: selectedList }, header).then((res) => {
      setLoading(false)
      setFollowData(res.data.data.items)
      setItemsPerPages(res.data.data.perPage)
      setTotalItems(res.data.data.totalRecords)
    }).catch((err) => {
      setLoading(false)
      errorMessage("Error", err.response.data.message)
    })
  }

  const ToggleDropdowns = (value) => {
    document.getElementById("open-filter-variation").classList.toggle("open-filter-variation")
  }

  return (
    <>
      {
        loading && <SimpleBackdrop />
      }
      <h2 className="dashboard-title">Follow Details</h2>
      <div className="main purchase-history-wrap">
        <div className='field-outer tabcontent-inner mt-20 mb-50' hidden={userData.role_label === "User" && true}>
          <label >Type :</label>
          <div id="open-filter-variation" className="search-variation" onClick={() => ToggleDropdowns("open-filter-variation")}>
            <div className="inputField">{selectedList ? selectedList : "Select"}
              <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path
                  d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 
                                                    0-45.3s-32.8-12.5-45.3 0L224 306.7 
                                                    86.6 169.4c-12.5-12.5-32.8-12.5-45.3 
                                                    0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            </div>
            <div className="search-variation-submenu ">
              <ul className="search-variation-list">
                <li onClick={() => setSelectedList("follower")}>Follower</li>
                <li onClick={() => setSelectedList("following")}>Following</li>
              </ul>
            </div>
          </div>
        </div>
        {followData.length > 0 ?
          <>
            <div className="tabcontent-wrap">
              <div className="tabcontent-content">
                <div className="tabcontent-row">
                  {followData?.length > 0 && followData?.map((item, index) => {
                    return (
                      <div key={item.id} className="tabcontent-column " onClick={() => navigate(`/contributor-login/${item?.username}`)}>
                        <div className="tabcontentcolumn-inner">
                          <img loading='lazy'
                            src={item?.avatar ?? userLogo} alt='Loading . . . . .' />
                          <div className="align-items-center tabcontentcolumn-info">
                            <p>{item?.first_name} {item.last_name}</p>
                            {/* <FontAwesomeIcon icon={faEye}  className={item.is_contributor ? "view-btn" : "d-none"} /> */}
                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </div>
            <Pagination itemsPerPage={itemsPerPage} TotalItems={totalItems} FocusPage={currentPage.slice(13)} />
          </>
          : loading ? <h3 className='nodDtaFound'>{AppTexts.Loading}.</h3> : <NoDataFound title={`No ${selectedList} record found`} />}
      </div>
    </>
  )
}

export default FollowDetail