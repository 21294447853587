import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const textFieldStyles = (hasError) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    "& fieldset": {
      borderColor: hasError ? "red !important" : "#d9d9d9 !important",
    },
    "&:hover fieldset": {
      borderColor: hasError ? "red !important" : "#d9d9d9 !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: hasError ? "red !important" : "#d9d9d9 !important",
      borderWidth: "1px",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#d9d9d9",
    "&.Mui-focused": {
      color: "#d9d9d9 !important",
    },
  },
});

const TextInput = ({
  label,
  name,
  type = "text",
  error,
  helperText,
  value,
  onChange,
  onBlur,
  showPassword,
  setShowPassword,
  multiline = false,
  rows = 1,
}) => {
  const isPasswordField = type === "password";

  return (
    <TextField
      fullWidth
      variant="outlined"
      label={label}
      name={name}
      type={isPasswordField && showPassword ? "text" : type}
      error={!!error}
      helperText={helperText}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      multiline={multiline}
      rows={multiline ? rows : undefined}
      InputProps={{
        endAdornment: isPasswordField && (
          <InputAdornment position="end" sx={{ marginRight: "5px" }}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
              sx={{
                fontSize: "1.0rem",
                marginRight: "5px",
              }}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={textFieldStyles(error)}
    />
  );
};

export default TextInput;