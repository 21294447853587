
const Storage = {
    SetTheme: (data) => localStorage.setItem("theme", JSON.stringify(data)),
    getTheme: () => localStorage.getItem("theme") ? JSON.parse(localStorage.getItem("theme")) : null,
    SetToken: (data) => localStorage.setItem("token", JSON.stringify(data)),
    getToken: () => localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null,
    SetRememberMeData: (data) => localStorage.setItem("rememberMeData", JSON.stringify(data)),
    getRememberMeData: () => localStorage.getItem("rememberMeData") ? JSON.parse(localStorage.getItem("rememberMeData")) : null,
    SetUserData: (data) => localStorage.setItem("userData", JSON.stringify(data)),
    getUserData: () => localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null,
    SetCartData: (data) => localStorage.setItem("cart", JSON.stringify(data)),
    getCartData: () => localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : { cart_items: [] },
    SetHeaderData: (data) => localStorage.setItem("header", JSON.stringify(data)),
    getHeaderData: () => localStorage.getItem("header") ? JSON.parse(localStorage.getItem("header")) : {},
    SetFooterData: (data) => localStorage.setItem("footer", JSON.stringify(data)),
    getFooterData: () => localStorage.getItem("footer") ? JSON.parse(localStorage.getItem("footer")) : {},
    SetPreferenceStep: (data) => localStorage.setItem("preferencestep", JSON.stringify(data)),
    getPreferenceStep: () => localStorage?.getItem("preferencestep") ? JSON.parse(localStorage?.getItem("preferencestep")) : null,
    SetSearchFilter: (data) => localStorage.setItem("searchfilter", JSON.stringify(data)),
    getSearchFilter: () => localStorage?.getItem("searchfilter") ? JSON.parse(localStorage?.getItem("searchfilter")) : null,
    removeData: (key) => localStorage.removeItem(key)
};

export default Storage;