import * as React from "react";
import PropTypes from "prop-types";
import {useAutocomplete} from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { useState } from "react";
import utilities from "../../utilites";
import { Typography, Box } from '@mui/material';
 
const Root = styled("div")(
  ({ theme }) => `
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 15px;
`
);

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#f4f4f4"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#f4f4f4"};
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    line-height: 40px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <button
        type="button"
        aria-label="remove black stock images"
        onClick={onDelete}
      >
        ✕
      </button>
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${theme.palette.mode === "dark" ? "#000" : "#edf2f7"};
  border: 1px solid ${theme.palette.mode === "dark" ? "#000" : "#ccc"};
  border-radius: 5px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border: none;
    background-color: ${theme.palette.mode === "dark" ? "#f4f4f4" : "#f4f4f4"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & button {
    font-size: 14px;
    margin-left: 5px;
    color: #000
  }
  & button:hover {
    color: #e74c3c
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

export default function CustomizedHook({ dataList, setData, selectedList }) {
  const [list] = useState(selectedList);
  
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    defaultValue: list,
    multiple: true,
    options: dataList,
    isOptionEqualToValue: (option, value) => option.name === value.name,
    getOptionLabel: (option) => option.name,
    onChange: (event, value) => setValues(value),
  });

  const setValues = (va) => {
    setData(va);
  };

  return (
    <Root>
      <div {...getRootProps()}>
        <Label {...getInputLabelProps()}>Select Release Forms</Label>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          {value.map((option, index) => (
            <StyledTag
              className="rti--tag"
              label={option.name}
              {...getTagProps({ index })}
            />
          ))}
        </InputWrapper>
        <input
          className="inputField"
          {...getInputProps()}
          placeholder="Select Release Forms"
        />
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 8px' }}>
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                  {option.name}
                </Typography>
                <Typography variant="caption" sx={{ mx: 2 }}>
                {`(${utilities.formatDateTime(option.created_at)})`}
                </Typography>
                <CheckIcon fontSize="small" />
              </Box>
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
}
