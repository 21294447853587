import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SimpleBackdrop from "../../components/Backdrop";
import { errorMessage, successMessage } from "../../helpers/Message";
import instance from "../../services/apiServices/Api";
import * as Yup from "yup";
import Storage from "../../services/local_storage/localStorage";
import { Autocomplete, TextField } from "@mui/material";

function CreateEditReleaseForm() {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = Storage.getToken("token");
  const header = { headers: { Authorization: `Bearer ${token}` } };
  const [country, setCountry] = useState([]);
  const [address, setAddress] = useState({ code: "", name: "" });
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState("");
  const gender = [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
    { name: "Other", value: "other" },
  ];
  useEffect(() => {
    if (id) {
      getData();
    }
    allCountry();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    setLoading(true);
    instance
      .post(`/contributor/releases`, { id: id }, header)
      .then((res) => {
        setLoading(false);
        setAddress({
          code: res?.data?.data?.nationality,
          name: res?.data?.data?.country_name,
        });
        for (let item in formik.initialValues) {
          if (["file"].includes(item)) {
            setFileName(res?.data?.data?.file.split("/")[4]);
            formik.setFieldValue("file", res?.data?.data?.file);
            continue;
          }
          if ([item] in res?.data?.data) {
            formik.setFieldValue(item, res?.data?.data[item]);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        errorMessage("Error", err.response?.data?.message);
      });
  };

  const ToggleDropdowns = (value) => {
    document
      .getElementById("open-filter-variation")
      .classList.toggle("open-filter-variation");
  };
  const multiSearch = (e, newInputValue) => {
    formik.setFieldValue("nationality", newInputValue?.code);
  };

  const allCountry = () => {
    instance
      .get(`/countries`)
      .then((res) => {
        setCountry(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
        errorMessage("Error", err.response?.data?.message);
      });
  };

  const fileChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0]?.name);
    formik.setFieldValue("file", "");
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      gender: "",
      nationality: "",
      file: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is Required"),
      gender: Yup.string().required("Gender is Required").nullable(),
      nationality: Yup.string().required("Nationality is Required").nullable(),
    }),

    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();

      if (!file.name) {
        if (!values?.file) {
          setLoading(false);
          errorMessage("Error", "The 'File' field is required ");
          return;
        }
      } else {
        formData.append("file", file);
      }

      if (id) {
        formData.append("release id", id);
      }

      formData.append("name", values.name);
      formData.append("gender", values.gender);
      formData.append("nationality", values.nationality);

      instance
        .post(
          `${
            id ? "/contributor/releases/update" : "/contributor/releases/create"
          }`,
          formData,
          header
        )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            id ? getData() : formik.resetForm();
            setFileName("");
            successMessage(
              "Success!",
              `Congratulations, your release has been successfully ${
                id ? "Updated" : "Created"
              }.`
            ).then(
              !id &&
                navigate(
                  state?.url
                    ? state?.url
                    : "/contributor-dashboard/release-form",
                  { state: { type: state?.type } }
                )
            );
          } else {
            let message = () => {
              for (let i in res.errors) {
                return `${res.errors[i]}`;
              }
            };
            errorMessage("Error!", message ?? "Something went wrong");
          }
        })
        .catch((error) => {
          setLoading(false);
          let message = Object?.values(error?.response?.data?.errors);
          errorMessage("Error!", message.join("\r\n"));
        });
    },
  });
  return (
    <> 
      {loading && <SimpleBackdrop />}
      <h2 className="dashboard-title">{id ? "Edit" : "Create"} Release Form</h2>
     
     
      <form
        className="contactForm--content auth-outer"
        action="#"
        onSubmit={formik.handleSubmit}
      >
        <div className="field-outer m-three-column">
          <input
            className="inputField"
            name="name"
            type="text"
            placeholder="Model Name"
            {...formik.getFieldProps("name")}
            value={formik.values.name ?? ""}
          />
          {formik.errors.name && formik.touched.name ? (
            <div className="validation-error">{formik.errors.name}</div>
          ) : null}
        </div>
        <div
          onClick={() => ToggleDropdowns("open-filter-variation")}
          className="field-outer m-three-column m-0"
        >
          <div id="open-filter-variation" className="search-variation">
            <div className="inputField">
              {gender.find((item) => item?.value === formik?.values?.gender)
                ?.name
                ? gender.find((item) => item?.value === formik?.values?.gender)
                    ?.name
                : "Select Gender"}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M201.4 374.6c12.5 12.5 32.8 12.5 
                                                    45.3 0l160-160c12.5-12.5 12.5-32.8 
                                                    0-45.3s-32.8-12.5-45.3 0L224 306.7 
                                                    86.6 169.4c-12.5-12.5-32.8-12.5-45.3 
                                                    0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg>
            </div>
            <div className="search-variation-submenu">
              <ul className="search-variation-list">
                {gender.map((item, i) => {
                  return (
                    <li
                      onClick={() => formik.setFieldValue("gender", item.value)}
                      key={i}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {formik.errors.gender && formik.touched.gender ? (
            <div className="validation-error">{formik.errors.gender}</div>
          ) : null}
        </div>
        <div className="field-outer m-three-column m-0">
          {address?.code && (
            <Autocomplete
              // id="country-select-demo"
              className=""
              options={country}
              sx={{ width: 300 }}
              autoHighlight
              onChange={multiSearch}
              defaultValue={{
                code: address?.code ?? "",
                name: address?.name ?? "",
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  className="inputField"
                  placeholder="Select Nationality"
                  {...params}
                />
              )}
            />
          )}
          {!id && (
            <Autocomplete
              // id="country-select-demo"
              className=""
              options={country}
              sx={{ width: 300 }}
              autoHighlight
              onChange={multiSearch}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  className="inputField"
                  placeholder="Select Nationality"
                  {...params}
                />
              )}
            />
          )}
          {formik.errors.nationality && formik.touched.nationality ? (
            <div className="validation-error">{formik.errors.nationality}</div>
          ) : null}
        </div>
        <div className="field-outer m-three-column">
          <input
            className="inputField"
            name="file"
            type="file"
            placeholder="File"
            onChange={(e) => fileChange(e)}
          />
          <div className="inputField file-browser">
            {fileName ? fileName : "Upload File"}
          </div>
          {formik.errors.file && formik.touched.file ? (
            <div className="validation-error">{formik.errors.file}</div>
          ) : null}
        </div>

        <div className="right-text contactForm--submit-btn field-outer full-width">
          {/* <button className="conatactForm-btn no-bg" type='reset'>cancel</button> */}
          {/* <input className="conatactForm-btn" type="submit" value="Save" /> */}
          <button className="btn  primary-btn btn-large" type="submit">
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
   
   
    </>
  );
}

export default CreateEditReleaseForm;
