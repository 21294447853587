import React, { useState } from "react";
import { Button, TextField, Box, Typography } from "@mui/material";
import { AppColors } from "../constants/AppColors";
import { AppTexts } from "../constants/AppTexts";

function NewsletterSignup() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lasttName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [formErrors, setFormErrors] = useState({
    email: false,
    firstName: false,
  });

  const validateForm = () => {
    const errors = {};
    if (!email.trim()) {
      errors.email = "Email is required";
    }
    if (!firstName.trim()) {
      errors.firstName = "First Name is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // True if no errors
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // If validation passes, manually submit the form
      event.target.submit();
      // Reset form fields after successful submission
      setEmail("");
      setFirstName("");
      setLastName("");
      setCountry("");
      setFormErrors({}); 
    }
  };

  return (
    <Box id="mc_embed_signup" sx={{ background: "#fff", padding: 2 }}>
      <form
        action="https://pichastock.us11.list-manage.com/subscribe/post?u=b87411dd44c54223e271dcc4b&amp;id=3c828fd309&amp;f_id=00c012e1f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate
        onSubmit={handleSubmit}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>Join our community</Typography>
        <Typography component="div" sx={{ mb: 1 }}>
          <span>**</span> indicates required
        </Typography>
        <TextField
          required
          error={Boolean(formErrors.email)}
          helperText={formErrors.email}
          id="mce-EMAIL"
          label="Email Address *"
          name="EMAIL"
          type="email"
          fullWidth
          variant="outlined"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          required
          error={Boolean(formErrors.firstName)}
          helperText={formErrors.firstName}
          id="mce-FNAME"
          label="First Name *"
          name="FNAME"
          type="text"
          fullWidth
          variant="outlined"
          margin="normal"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <TextField
          id="mce-LNAME"
          label="Last Name"
          name="LNAME"
          type="text"
          fullWidth
          variant="outlined"
          margin="normal"
          value={lasttName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          id="mce-MMERGE3"
          label="Country"
          name="MMERGE3"
          type="text"
          fullWidth
          variant="outlined"
          margin="normal"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="submit"
            name="subscribe"
            id="mc-embedded-subscribe"
            variant="contained"
            sx={{
              // padding: 2,
              backgroundColor: AppColors.PrimaryColor,
              color: AppColors.WhiteColor,
              borderRadius: "20px", 
              "&:hover": {
                backgroundColor: AppColors.PrimaryHoverColor,
              },
            }}
          >
            {AppTexts.Subscribe}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default NewsletterSignup;
