import React from 'react';
import { Box, Button as MuiButton } from '@mui/material';
import { AppColors } from '../constants/AppColors';

export const buttonSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

export const buttonVariants = {
  PRIMARY: 'primary',
  DEFAULT: 'default',
  SECONDARY: 'secondary',
};

const AppCustomButton = ({
  children,
  icon,
  variant = buttonVariants.PRIMARY,
  size = buttonSizes.MEDIUM,
  onClick,
  ...props
}) => {
  const styles = {
    [buttonVariants.PRIMARY]: {
      backgroundColor: AppColors.PrimaryColor,
      color: '#fff',
      border: AppColors.PrimaryColor,
      '&:hover': {
        backgroundColor: AppColors.PrimaryHoverColor,
      },
    },

    [buttonVariants.DEFAULT]: {
      backgroundColor: AppColors.PrimaryColor,
      color: '#fff',
      border: 'none',
      '&:hover': {
        backgroundColor: AppColors.PrimaryHoverColor,
      },
    },
    [buttonVariants.SECONDARY]: {
      backgroundColor: 'transparent',
      color: AppColors.PrimaryColor,
      border: `1px solid ${AppColors.PrimaryColor}`,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    [buttonSizes.LARGE]: {
      height: '48px',
      width: '100%',
      borderRadius: '25px',
    },
    [buttonSizes.MEDIUM]: {
      height: '38px',
      borderRadius: '20px',
    },
    [buttonSizes.SMALL]: {
      height: '28px',
      borderRadius: '15px',
    },
  };

  const iconWithColor = icon ? React.cloneElement(icon, { style: { color: styles[variant].color } }) : null;

  return (
    <Box sx={{ width: '100%' }}>
      <MuiButton
        startIcon={iconWithColor}
        sx={{
          ...styles[variant],
          ...styles[size],
          textTransform: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={onClick}
        {...props}
      >
        {children}
      </MuiButton>
    </Box>
  );
};

export default AppCustomButton;
