import React, { useEffect } from 'react'
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    // CategoryScale,
    // LinearScale,
    // PointElement,
    // LineElement,
    // Title,
    // Tooltip,
    // Legend,
} from 'chart.js/auto';

function ChartStats({ Lable, StatTitle, DataLable, Data }) {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            // title: {
            //     display: true,
            //     text: StatTitle,
            // },
        },
    };
    const statsData = {
        labels: Lable,
        datasets: [
            {
                label: DataLable,
                data: Data,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };


    useEffect(() => {
        ChartJS.register()
        //     CategoryScale,
        //     LinearScale,
        //     PointElement,
        //     LineElement,
        //     Title,
        //     Tooltip,
        //     Legend
        // );


        // eslint-disable-next-line    
    }, [])
    return (
        <div className='m-half-width'>
            <h1 className='subheading'>{StatTitle}</h1>
            <Line options={options} data={statsData} />
        </div>
    )
}

export default ChartStats