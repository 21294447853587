import fileDownloadreducer from './slices/fileSlice'
import isAuthreducer from "./slices/isAuth"
import addToCartreducer from './slices/cartSlice'
import menusreducer from './slices/menuSlice'

const rootReducer = {
    fileDownload: fileDownloadreducer,
    isAuth: isAuthreducer,
    addToCart: addToCartreducer,
    menus: menusreducer,
}

export default rootReducer;