import React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { textFieldStyles } from "./TextInput";

const CountrySelect = ({ options, label, name, error, helperText, onChange, value, onBlur }) => {
  return (
    <Autocomplete
      options={options}
      autoHighlight
      value={options.find(option => option.code === value) || null}
      isOptionEqualToValue={(option, value) => option.code === value}
      onChange={(event, newValue) => {
        onChange(name, newValue ? newValue.code : "");
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          name={name}
          error={error}
          helperText={helperText}
          sx={textFieldStyles(error)}
          onBlur={onBlur}
        />
      )}
    />
  );
};

export default CountrySelect;
