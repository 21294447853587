import Swal from "sweetalert2";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export const errorMessage = (title, text) => {
  return Swal.fire({
    title: title,
    text: text,
    icon: "error",
    confirmButtonText: "Okay",
  });
};

export const successMessage = (title, text, html) => {
  return Swal.fire({
    title: title,
    text: text,
    html: html,
    icon: "success",
    confirmButtonText: "Okay",
  });
};

export const isLoggedInMsg = (text) => {
  return Swal.fire({
    position: "center",
    icon: "success",
    title: text,
    showConfirmButton: false,
    timer: 1500,
  });
};

export const errorMessageAlert = (title, text) => {
  return (
    <Alert severity="error">
      <AlertTitle>{'title'}</AlertTitle>
      {'text'}
    </Alert>
  );
};
