import { createSlice } from "@reduxjs/toolkit"
import { errorMessage } from "../../helpers/Message"
import instance from "../../services/apiServices/Api"
import Storage from "../../services/local_storage/localStorage"



const initialState = {
    dataOfHeader: {},
    dataOfFooter: {
        about_picha: {},
        more_links: {},
        photographers: {}
    }
}


const menus = createSlice({
    name: "menus",
    initialState: initialState,
    reducers: {
        onCallMenus: function (state, actoin) {
            state.dataOfHeader = actoin.payload.dataOfHeader
            state.dataOfFooter.about_picha = actoin.payload.dataOfFooter.about_picha
            state.dataOfFooter.more_links = actoin.payload.dataOfFooter.more_links
            state.dataOfFooter.photographers = actoin.payload.dataOfFooter.photographers
            state.dataOfFooter.no_solutions = actoin.payload.dataOfFooter.no_solutions
        }
    }
})

export function getMenuRedux() {

    return async dispatch => {
        const token = Storage.getToken("token");
        const header = { headers: { Authorization: `Bearer ${token}` } }
        instance.post('/menus', {}, header).then((res) => {
            const common = res.data.data;
            if (common.header.items.length > 0) {
                Storage.SetHeaderData(common.header);
            }
            if (common.about_picha.items.length > 0 || common.more_links.items.length > 0 || common.photographers.items.length > 0 || common.no_solutions.items.length > 0) {
                Storage.SetFooterData({
                    about_picha: common.about_picha,
                    more_links: common.more_links,
                    photographers: common.photographers,
                    no_solutions: common.no_solutions
                });
            }
            dispatch(onCallMenus({
                dataOfHeader: common.header,
                dataOfFooter: {
                    about_picha: common.about_picha,
                    more_links: common.more_links,
                    photographers: common.photographers,
                    no_solutions: common.no_solutions
                }
            }))
        }).catch((err) => {
            if (Storage.getHeaderData?.items?.length < 0 || Storage.getFooterData !== {}) {
                errorMessage("Error", err.response.message)
            }
        });
    }

}

export const { onCallMenus } = menus.actions;
export default menus.reducer