import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import userLogo from "../assets/user-logo.png";
import image1 from "../assets/placeholder.png";
import FollowButton from "./FollowButton";
import { ClipBoardIcon } from "../constants/AppSVGIcons";
import { AppColors } from "../constants/AppColors";
import utilities from "../utilites";

function Card({ item, featuredContributor }) {
  const navigate = useNavigate();
  const [img1Loader, setLoader1] = useState(true);
  const [img2Loader, setLoader2] = useState(true);
  const [img3Loader, setLoader3] = useState(true);
  return (
    <>
      {featuredContributor ? (
        <div className="collection--multiimg-box">
          <div className="collection--multiimg-box-img">
            <div className="collection--multiimg-left">
              {img1Loader && <img src={image1} alt="" />}
              <img
                onLoad={() => setLoader1(false)}
                // src="https://pichastockv2-dev-resize-media.s3.amazonaws.com/assets/testImages/190x220.jpg"
                src={item.downloads[0]?.watermarked_file_small?.file ?? image1}
                alt="collection-img"
              />
            </div>
            <div className="collection--multiimg-right">
              <div className="collection--multiimg-right-top">
                {img2Loader && <img src={image1} alt="" />}
                <img
                  onLoad={() => setLoader2(false)}
                  // src="https://pichastockv2-dev-resize-media.s3.amazonaws.com/assets/testImages/108x108-1.jpg"
                  src={
                    item.downloads[1]?.watermarked_file_small?.file ?? image1
                  }
                  alt="collection-img"
                />
              </div>
              <div className="collection--multiimg-right-bottom">
                {img3Loader && <img src={image1} alt="" />}
                <img
                  onLoad={() => setLoader3(false)}
                  // src="https://pichastockv2-dev-resize-media.s3.amazonaws.com/assets/testImages/108x108-2.jpg"
                  src={
                    item.downloads[2]?.watermarked_file_small?.file ?? image1
                  }
                  alt="collection-img"
                />
              </div>
            </div>
          </div>
          <div className="collection--multiimg-view author-info-outer">
            <div
              className="author-info-wrap d-flex align-items-center"
              onClick={() => navigate(`/contributor-login/${item?.username}`)}
            >
              <div className="author-info-image">
                <img
                  className="imgWidth"
                  src={item?.avatar ?? userLogo}
                  alt="error"
                />
              </div>
              <p>{item.first_name + " " + item.last_name}</p>
            </div>
            <FollowButton
              item={item}
              itemClass="btn btn-small follow-btn primary-btn"
            />
          </div>
        </div>
      ) : (
        <div
          className="collection--multiimg-box"
          onClick={() =>
            navigate(`/downloads/tag/${item.slug}`, {
              state: { name: item.name },
            })
          }
        >
          <div className="collection--multiimg-box-img">
            <div className="collection--multiimg-left">
              {img1Loader && <img src={image1} alt="" />}
              <img
                onLoad={() => setLoader1(false)}
                // src="https://pichastockv2-dev-resize-media.s3.amazonaws.com/assets/testImages/190x220.jpg"
                src={item.downloads[0]?.watermarked_file_small?.file ?? image1}
                alt="collection-img"
              />
            </div>
            <div className="collection--multiimg-right">
              <div className="collection--multiimg-right-top">
                {img2Loader && <img src={image1} alt="" />}
                <img
                  onLoad={() => setLoader2(false)}
                  // src="https://pichastockv2-dev-resize-media.s3.amazonaws.com/assets/testImages/108x108-1.jpg"
                  src={
                    item.downloads[1]?.watermarked_file_small?.file ?? image1
                  }
                  alt="collection-img"
                />
              </div>
              <div className="collection--multiimg-right-bottom">
                {img3Loader && <img src={image1} alt="" />}
                <img
                  onLoad={() => setLoader3(false)}
                  // src="https://pichastockv2-dev-resize-media.s3.amazonaws.com/assets/testImages/108x108-2.jpg"
                  src={
                    item.downloads[2]?.watermarked_file_small?.file ?? image1
                  }
                  alt="collection-img"
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              // margin: "15px",
              marginTop: '8px',
              marginLeft: '10px',
              // background:'red',
            }}
          >
            <ClipBoardIcon
              width={23}
              height={23}
              color={AppColors.BlackColor}
            />

            <h1
              className="collection_headings_2"
              style={{ marginLeft: "10px" }}
            >
              {item?.name ? utilities.toTitleCase(item.name) : ""}
            </h1>
          </div>
          {/* <div className="collection--multiimg-view">
            <ClipBoardIcon width={30} height={30} color={AppColors.PrimaryColor} />  
            <p className="">{item.name}</p>
          </div> */}
        </div>
      )}
    </>
  );
}

export default Card;
