import React from 'react'
import { Route, Routes } from "react-router-dom";
import Dashboard from '../../pages/Contributor/Dashboard';
import ProfileUpdate from '../../pages/Contributor/ProfileUpdate';
import AddImage from '../../pages/Contributor/AddImage';
import Gallery from '../../pages/Contributor/Gallery';
import Earnings from '../../pages/Contributor/Earnings';
import Orders from '../../pages/Contributor/Orders';
import Purchases from '../../pages/Contributor/Purchases';
import FollowDetail from '../../pages/Contributor/FollowDetail';
import ProductListing from '../../pages/Contributor/ProductListing';
import ReleaseForm from '../../pages/Contributor/ReleaseForm';
import OrderDetail from '../../pages/Contributor/OrderDetail';
import CreateEditReleaseForm from '../../pages/Contributor/CreateEditReleaseForm';
import ReleaseFormDetail from '../../pages/Contributor/ReleaseFormDetail';
import GalleryEditDownload from '../../pages/Contributor/GalleryEditDownload';
import PageNotFound from '../../pages/PageNotFound';
// import MaintenancePage from '../../pages/Contributor/MaintenancePage';

function Content() {
  return (
    <>
      <div className="dashboard-content-inner min-h-empty">
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/profile' element={<ProfileUpdate />} />
          {/* <Route path='/multiple-upload-dev-test' element={<AddImage />} /> */}
          <Route path='/multiple-upload' element={<AddImage />} />
          {/* <Route path='/multiple-upload' element={<MaintenancePage />} /> */}
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/earnings/*' element={<Earnings />} />
          <Route path='/orders/*' element={<Orders />} />
          <Route path='/purchase_history/*' element={<Purchases />} />
          <Route path='/following_items/*' element={<FollowDetail />} />
          <Route path='/listing' element={<ProductListing />} />
          <Route path='/release-form/*' element={<ReleaseForm />} />
          <Route path='/release-form/detail/:id' element={<ReleaseFormDetail />} />
          <Route path='/release-form/create' element={<CreateEditReleaseForm />} />
          <Route path='/release-form/edit/:id' element={<CreateEditReleaseForm />} />
          <Route path='/orders/detail' element={<OrderDetail />} />
          <Route path='/gallery/edit/:id' element={<GalleryEditDownload />} />
          <Route path="*" element={<PageNotFound redirect="404" />} />

        </Routes>
      </div>
    </>
  )
}

export default Content