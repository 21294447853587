import React, { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import pagenotfound from "../assets/pageNotFound.svg";
import SEO from '../components/SEO';

export default function PageNotFound({ redirect }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (redirect === '404') {
      navigate("/not-found");
    }
    // eslint-disable-next-line
  }, [redirect, navigate]);  // Adding necessary dependencies

  return (
    <>
      <SEO 
        title="404 Page Not Found | PichaStock" 
        description="Oops! The page you're looking for doesn't exist. Head back to the home page to explore more."
        canonical="/not-found"
      />
      <div className="container">
        <div className="align-items-center flex-wrap main-page-outer outer-row">
          <div className="column-medium-6 column-12 ">
            <div className="text-center member-column rounded-corner">
              <h2>Page Not Found</h2>
              <p className="mt-3">
                We're sorry, but the page you are looking for doesn't exist or has been moved.
              </p>
              <div className="sizes-btn-outer mt-3">
                <Link to="/" className="btn primary-btn btn-large">Back to Home</Link>
              </div>
            </div>
          </div>
          <div className="column-medium-6 column-12 ">
            <img 
              src={pagenotfound} 
              alt="404" 
              className='full-width' 
              loading="lazy" 
            />
          </div>
        </div>
      </div>
    </>
  );
}
