import { createSlice } from "@reduxjs/toolkit"
import Storage from "../../services/local_storage/localStorage";

const initialState = {
    value: Storage.getToken("token") ? true : false
}

const isAuth = createSlice({
    name: "isAuth",
    initialState: initialState,
    reducers: {
        onCallIsAuth: function (state, actoin) {
            state.value = actoin.payload.value
        }
    }
})

export const { onCallIsAuth } = isAuth.actions;
export default isAuth.reducer