import { useState, useEffect } from 'react'

function ScrollToTop() {

  const [is_visible, setIs_visible] = useState(false)

  useEffect(() => {
    document.addEventListener("scroll", function (e) {
      toggleVisibility();
    });
  }, [])

  const toggleVisibility = () => {
    if (window.pageYOffset > 1080) {
      setIs_visible(true)
    } else {
      setIs_visible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  return (
    <>
      {is_visible &&


        <button className='topbtn' onClick={() => scrollToTop()}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" /></svg></button>


      }
    </>


  )
}

export default ScrollToTop