import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SimpleBackdrop from "../../components/Backdrop";
import { errorMessage } from "../../helpers/Message";
import instance from "../../services/apiServices/Api";
import Storage from "../../services/local_storage/localStorage";

function ReleaseFormDetail() {
  const { id } = useParams();
  const token = Storage.getToken("token");
  const header = { headers: { Authorization: `Bearer ${token}` } };
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    setLoading(true);
    instance
      .post(`/contributor/releases`, { id: id }, header)
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        errorMessage("Error", err.response.data.message);
      });
  };
  return (
    <>
      {loading && <SimpleBackdrop />}
      <h2 className="dashboard-title">Release Form Detail</h2>

      <div className="thankyou-page tbl-main ">
        <div className="tble-clm">
          {/* <h2 className="section-heading">Release Form INFORMATION</h2> */}
          <table>
            <thead>
              <tr>
                <th>Release</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Model Name</td>
                <td>{data?.name}</td>
              </tr>
              <tr>
                <td>Model Gender</td>
                <td>{data?.gender}</td>
              </tr>
              <tr>
                <td>Model Nationality</td>
                <td>{data?.nationality}</td>
              </tr>
              <tr>
                <td>Associated Count</td>
                <td>{data?.download_associated_count}</td>
              </tr>
              <tr>
                <td>Associated Sold Count</td>
                <td>{data?.download_associated_sold_count}</td>
              </tr>
              <tr>
                <td>File</td>
                <td>
                  <span
                    className="dwnload-btn"
                    onClick={() => window.open(data.file, "_blank")}
                  >
                    View
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ReleaseFormDetail;
