import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { TagsInput } from "react-tag-input-component";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import instance from "../../services/apiServices/Api";
import { errorMessage, successMessage } from "../../helpers/Message";
import img from "../../assets/imagePlaceholder.jpg";
import VideoPlaceholder from "../../assets/videoPlaceholder.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CustomizedHook from "../../components/customHooks/CustomizedHook";
import { AppTexts } from "../../constants/AppTexts";
import ReleaseFormModal from "../../components/ReleaseFormModal";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Switch,
  Box,
} from "@mui/material";
import { AppColors } from "../../constants/AppColors";

function ProductListing() {
  // let { state } = useLocation();
  const [selected, setSelected] = useState([]);
  const [releaseSelect, setReleaseSelected] = useState([]);
  const [data, setData] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [releaseInfo, setReleaseInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(0);
  const [releaseList, setReleaseList] = useState([]);
  const [imgLoader, setLoader] = useState(false);

  const [generativeTags] = useState([]);

  const { state } = useLocation();
  const [isAIGenerated, setIsAIGenerated] = useState(false);
  const [isFictional, setIsFictional] = useState(false);



  let apidata = useMemo(
    () => ({
      type: state?.type,
      current_page: 1,
      per_page: 20,
      status: 2,
      order_by: "newness_desc",
    }),
    [state?.type]
  );

  useEffect(() => {
    getData(apidata, "default");
  }, [apidata]);


  const formik = useFormik({
    initialValues: {
      download_id: [],
      title: "",
      description: "",
      media_type: "creative",
      is_recognized: "0",
      release_id: [],
      submit_for_approval: "",
      tags: [],
      is_aigenerated: "0",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Title is Required")
        .max(255, "Title must be less then 255 characters"),
      description: Yup.string()
        .required("Description is Required")
        .max(250, "Title must be less then 250 characters"),
      media_type: Yup.string().required("Media type is Required"),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);

      if (values.is_aigenerated === "1" && isFictional === false) {
        setLoading(false);
        return errorMessage(AppTexts.Error, AppTexts.CheckPeopleAreFictional);
      }

      if (selected.length >= 6) {
        if (values.is_recognized === "1") {
          if (values?.release_id?.length <= 0 || !values.release_id) {
            setLoading(false);
            return errorMessage("Error", "Please Select ReleaseForm");
          }
        }

        let data = {
          ...values,
          tags: [...selected, ...generativeTags],
          // tags: selected,
          download_id: values.download_id,
          is_recognized: parseInt(values.is_recognized),
          is_aigenerated: parseInt(values.is_aigenerated),
          // is_aigenerated: parseInt(isAIGenerated),
        };

        instance
          .post("contributor/downloads/update", data)
          .then((res) => {
            setLoading(false);
            if (state?.type === "video") {
              values.download_id.length > 1
                ? values.submit_for_approval === 1
                  ? successMessage(
                      "Success",
                      "Video’s have been submitted for approval"
                    )
                  : successMessage(
                      "Success",
                      "Video’s have been saved successfully"
                    )
                : values.submit_for_approval === 1
                ? successMessage(
                    "Success",
                    "Video has been submitted for approval"
                  )
                : successMessage(
                    "Success",
                    "Video has been saved successfully"
                  );
            } else {
              values.download_id.length > 1
                ? values.submit_for_approval === 1
                  ? successMessage(
                      "Success",
                      "Image’s have been submitted for approval"
                    )
                  : successMessage(
                      "Success",
                      "Image’s have been saved successfully"
                    )
                : values.submit_for_approval === 1
                ? successMessage(
                    "Success",
                    "Image has been submitted for approval"
                  )
                : successMessage(
                    "Success",
                    "Image has been saved successfully"
                  );
            }

            setSelected([]);
            setIsCheckAll(false);
            setReleaseSelected([]);
            resetForm();
            formik.setFieldValue("download_id", []);
            Array.from(document.querySelectorAll(".selected-img")).forEach(
              (el) => el.classList.remove("selected-img")
            );

            getData(apidata, "default");
          })
          .catch((error) => {
            setLoading(false);
            errorMessage("Error", "Something went wrong");
          });
      } else {
        setLoading(false);
        errorMessage("Error", "Required minimum 6 keywords");
      }
    },
  });

  const getData = (apidata, type) => {
    setLoading(true);
    instance
      .post("contributor/downloads", apidata)
      .then((res) => {
        setLoading(false);
        setNextPage(res?.data?.data?.nextPageNo);
        setReleaseList(res?.data?.data?.releases);
        setData((preData) => {
          return type === "loadMore"
            ? [...new Set([...preData, ...res?.data?.data?.downloads])]
            : [...new Set(res?.data?.data?.downloads)];
        });
      })
      .catch((error) => setLoading(false));
  };


  const getTags = async (ids) => {
    try {
      const response = await instance.post("contributor/downloads", {
        download_ids: ids,
        type: state?.type,
      });
      let allTags = response.data.data.downloads.reduce(
        (accumulator, download) => {
          const downloadTags = download.tags.map((tag) => tag.name);
          return accumulator.concat(downloadTags); // Concatenate instead of nesting arrays
        },
        []
      );

      // Deduplicate tags before filtering for AI-generated or not
      const uniqueTags = [...new Set(allTags)];

      const filteredTags = uniqueTags.filter((tag) => {
        if (formik.values.is_aigenerated === "1") {
          // if (isAIGenerated) {

          return true;
        }

        return !["AI", "Generative Image", "Generative"].includes(tag);
      });

      // return filteredTags; 
      setSelected(filteredTags);
    } catch (err) {
      errorMessage("Error", err?.response?.data?.message);
      // return []; 
      setSelected([]);
    }
  };


  const selectCheckbox = (e, id) => {
    const { checked } = e.target;
    let value = formik.values.download_id;
    if (checked) {
      const selectedDownloads = [...value, id];
      document.getElementById(id).classList.add("selected-img");
      formik.setFieldValue("download_id", selectedDownloads);
      const selectedIdData = data?.find((item) => item.id === id);

      if (selectedIdData.is_aigenerated === 1) {
        setIsFictional(true);
      }

      formik.setFieldValue(
        "title",
        selectedIdData.title === null ? "" : selectedIdData.title
      );
      formik.setFieldValue(
        "description",
        selectedIdData.description === null ? "" : selectedIdData.description
      );
      formik.setFieldValue(
        "media_type",
        selectedIdData.media_type === null ? "" : selectedIdData.media_type
      );
      formik.setFieldValue(
        "is_recognized",
        selectedIdData.is_recognized ? "1" : "0"
      );

      formik.setFieldValue(
        "is_aigenerated",
        selectedIdData.is_aigenerated ? "1" : "0"
      );
      formik.setFieldValue(
        "release_id",
        selectedIdData.release
          ? selectedIdData.release.map((item, i) => item.release_id)
          : []
      );
      setReleaseInfo(selectedIdData?.release?.release_info);
      // setSelected(selectedIdData.tags.map(item => item.name))
      value.length === data.length - 1
        ? setIsCheckAll(true)
        : setIsCheckAll(false);

      getTags(selectedDownloads);
      /* if (value.length < 1) {
            } else {
                setSelected([])
            } */
      setReleaseSelected(
        selectedIdData.release.map((item) => item.release_info)
      );
    } else {
      const selectedDownloads = value.filter((item) => item !== id);

      setIsCheckAll(false);
      document.getElementById(id).classList.remove("selected-img");
      formik.setFieldValue("download_id", selectedDownloads);
      formik.setFieldValue("title", "");
      formik.setFieldValue("description", "");
      formik.setFieldValue("media_type", "");
      formik.setFieldValue("is_recognized", "0");
      formik.setFieldValue("is_aigenerated", "0");
      formik.setFieldValue("release_id", []);
      setReleaseInfo({});
      getTags(selectedDownloads);

      if (selectedDownloads.length > 0) {
        let selectedIdData = data?.find(
          (item) => item.id === selectedDownloads[0]
        );

        formik.setFieldValue(
          "title",
          selectedIdData?.title === null ? "" : selectedIdData?.title
        );
        formik.setFieldValue(
          "description",
          selectedIdData?.description === null
            ? ""
            : selectedIdData?.description
        );
        formik.setFieldValue(
          "media_type",
          selectedIdData?.media_type === null ? "" : selectedIdData?.media_type
        );
        formik.setFieldValue(
          "is_recognized",
          selectedIdData.release.length > 0 ? "1" : "0"
        );

        formik.setFieldValue(
          "is_aigenerated",
          selectedIdData.is_aigenerated.length > 0 ? "1" : "0"
        );

        formik.setFieldValue(
          "release_id",
          selectedIdData?.release === null
            ? ""
            : selectedIdData?.release.release_id
        );
        setReleaseInfo(selectedIdData?.release?.release_info);
      } else {
        setSelected([]);
      }
    }
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    if (!isCheckAll) {
      getTags([data[0].id]);

      let selectedIdData = data?.find((item) => item.id === data[0].id);

      formik.setFieldValue(
        "title",
        selectedIdData?.title === null ? "" : selectedIdData?.title
      );
      formik.setFieldValue(
        "description",
        selectedIdData?.description === null ? "" : selectedIdData?.description
      );
      formik.setFieldValue(
        "media_type",
        selectedIdData?.media_type === null ? "" : selectedIdData?.media_type
      );
      formik.setFieldValue(
        "is_recognized",
        selectedIdData.release.length > 0 ? "1" : "0"
      );

      formik.setFieldValue(
        "is_aigenerated",
        selectedIdData.is_aigenerated > 0 ? "1" : "0"
      );

      formik.setFieldValue(
        "release_id",
        selectedIdData?.release === null
          ? ""
          : selectedIdData?.release.release_id
      );

      /* if (data.length < 2) {
            } else {
                setSelected([])
                formik.resetForm()
            } */

      for (let i in data) {
        document.getElementById(data[i].id).classList.add("selected-img");
      }

      formik.setFieldValue(
        "download_id",
        data?.map((li) => li.id)
      );
    } else {
      setSelected([]);
      formik.resetForm();

      Array.from(document.querySelectorAll(".selected-img")).forEach((el) =>
        el.classList.remove("selected-img")
      );

      formik.setFieldValue("download_id", []);
    }
  };

  const deleteImages = () => {
    setLoading(true);
    if (formik.values.download_id.length > 0) {
      instance
        .post("contributor/downloads/destroy", {
          download_id: formik.values.download_id,
        })
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            getData(apidata, "default");
            formik.resetForm();
            setSelected([]);
            setIsCheckAll(false);
            formik.setFieldValue("download_id", []);
            successMessage("Success", "Deleted successfully");
          }
        })
        .catch((error) => {
          setLoading(false);
          errorMessage("Error", "Something went wrong");
        });
    } else {
      setLoading(false);
      toast.error("Please select file", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const LoadMore = () => {
    if (nextPage > 1) {
      apidata = { ...apidata, type: state?.type, current_page: nextPage };
      getData(apidata, "loadMore");
    } else {
      // console.log('No more data to show');
      toast.error("No more data to show");
    }
  };

  const multiSearch = (newInputValue) => {
    const release_ids = newInputValue.map((re) => re.id);
    formik.setFieldValue("release_id", release_ids);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Refresh function
  const refreshReleaseList = () => {
    // Call the API to refresh the list
    getData(apidata, "default");
  };

  const handleGenerativeTags = (isGenerated) => {
    if (isGenerated === "1") {
      // If AI generated is checked, potentially add generative tags if not already present
      const newTags = [
        ...new Set([...selected, "AI", "Generative Image", "Generative"]),
      ];
      setSelected(newTags);
    } else {
      // If AI generated is unchecked, remove the specific generative tags
      const filteredTags = selected.filter(
        (tag) => !["AI", "Generative Image", "Generative"].includes(tag)
      );
      setSelected(filteredTags);
    }
  };

  const handleAIGeneratedChange = (event) => {
    const isGenerated = event.target.checked;
    setIsAIGenerated(isGenerated);
    formik.setFieldValue("is_aigenerated", isGenerated ? "1" : "0");
    // Optionally handle generative tags based on the switch's state
    handleGenerativeTags(isGenerated ? "1" : "0");
  };


const handleTagChange = (newTags) => {
  // Splitting each entry by commas, trimming, and removing empty strings
  const processedTags = newTags
    .flatMap(tag => tag.split(',').map(subTag => subTag.trim()))
    .filter(tag => tag !== "");
  setSelected(processedTags);
};


  return (
    <div>
      <ToastContainer />
      <div className="dashboard-imageupdate imgupload-sidebar-white">
        <form onSubmit={formik.handleSubmit} className="imgupload-form">
          <div className="imgupload-wrap">
            <div className="imgupload-content">
              <div className="imgupload-content-flex">
                <div
                  hidden={data?.length === 0}
                  className="imgupload-content-checkbox"
                >
                  <input
                    type="checkbox"
                    name="download_id"
                    checked={
                      isCheckAll ||
                      data?.length === formik.values.download_id?.length
                    }
                    onChange={() => {
                      handleSelectAll();
                    }}
                  />
                  <label>Select All</label>
                </div>
                <div
                  hidden={data?.length === 0}
                  className="imgupload-content-btn"
                >
                  <button
                    type="button"
                    onClick={deleteImages}
                    className="btn primary-btn btn-medium delete-btn"
                  >
                    Delete Selected {`${state?.type}s`}
                  </button>
                </div>
              </div>

              <div className="dashboard-imageupdate-row">
                {data?.length > 0 ? (
                  data?.map((item, index) => {
                    return (
                      <div
                        id={item.id}
                        key={item.id}
                        className="dashboard-imageupdate-column"
                      >
                        <div className="dashboard-imageupdate-inner">
                          {!imgLoader && (
                            <img
                              src={
                                state?.type === "video" ? VideoPlaceholder : img
                              }
                              alt=""
                            />
                          )}

                          <img
                            onLoad={() => setLoader(true)}
                            loading="lazy"
                            src={
                              item?.watermarked_file?.file
                                ? item?.watermarked_file?.file
                                : img
                            }
                            alt="Loading . . . . ."
                          />

                          {/* <img src={VideoPlaceholder} /> */}

                          <input
                            type="checkbox"
                            name="download_id"
                            value={item.id}
                            onChange={(e) => selectCheckbox(e, item.id)}
                            checked={formik.values.download_id.includes(
                              item.id
                            )}
                          />
                          <span
                            className="slectedDot"
                            hidden={item.media_type == null && true}
                          >
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : loading ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "0 20px",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={120}
                        height={40}
                        animation="wave"
                        style={{ borderRadius: "15px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={220}
                        height={40}
                        animation="wave"
                        style={{ borderRadius: "15px" }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: "30px",
                      }}
                    >
                      {Array.from(new Array(8)).map((_, index) => (
                        <div key={index} style={{ width: "100px" }}>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={100}
                            animation="wave"
                            style={{ borderRadius: "15px" }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="noRecord">
                    <h4 className="section-heading">{`No ${state?.type}s available yet`}</h4>
                  </div>
                )}
              </div>
              <div className="pichastock-btn-outer text-center">
                {nextPage > 0 && (
                  <button
                    onClick={() => LoadMore()}
                    className="btn primary-btn pichastock--btn mt-50 "
                    type="button"
                  >
                    Load More
                  </button>
                )}
              </div>
            </div>
            <div className="imgupload-sidebar">
                <>
                  <h2 className="dashboard-title text-center">
                    File Information
                  </h2>
                  <div className="field-outer field-outer-radio-wrap ">
                    <label>{AppTexts.RecognizablePeople}</label>
                    <div className="radio-wrap">
                      <input
                        name="is_recognized"
                        type="radio"
                        value="1"
                        checked={formik.values.is_recognized >= 1}
                        onChange={(e) => {
                          formik.setFieldValue("is_recognized", e.target.value);
                          formik.setFieldValue("release_id", []);
                        }}
                      />
                      <label>Yes</label>
                    </div>

                    <div className="radio-wrap">
                      <input
                        name="is_recognized"
                        type="radio"
                        value="0"
                        checked={formik.values.is_recognized === "0"}
                        onChange={(e) => {
                          formik.setFieldValue("is_recognized", e.target.value);
                          formik.setFieldValue("release_id", []);
                        }}
                      />
                      <label>No</label>
                    </div>
                  </div>
                  <div
                    className={
                      formik.values.is_recognized === "0"
                        ? "field-outer field-outerclose"
                        : "field-outer field-outeropen"
                    }
                    hidden={releaseList.length <= 0 ? true : false}
                  >
                    {releaseInfo?.id || formik.values.is_recognized >= 1 ? (
                      <CustomizedHook
                        dataList={releaseList}
                        setData={multiSearch}
                        selectedList={releaseSelect}
                      />
                    ) : // <Autocomplete
                    //   // id="country-select-demo"
                    //   className=""
                    //   options={releaseList}
                    //   sx={{ width: 300 }}
                    //   autoHighlight
                    //   onChange={multiSearch}
                    //   defaultValue={{
                    //     id: releaseInfo?.id ?? "",
                    //     name: releaseInfo?.name ?? "",
                    //   }}
                    //   getOptionLabel={(option) => option.name}
                    //   renderInput={(params) => (
                    //     <TextField
                    //       className="inputField"
                    //       placeholder="Select Release Form"
                    //       {...params}
                    //     />
                    //   )}
                    // />
                    null}
                  </div>

                  <ReleaseFormModal
                    open={open}
                    onClose={handleClose}
                    onRefresh={refreshReleaseList}
                  />

                  <div
                    className={
                      formik.values.is_recognized === "0"
                        ? "field-outer field-outerclose"
                        : "field-outer field-outeropen"
                    }
                    hidden={formik?.values?.release_id?.length > 0}
                  >
                    <p hidden={releaseList.length <= 0}>Or</p>
                    <p>Create a new release</p>
                    <Link
                      className="btn primary-btn btn-medium"
                      onClick={handleOpen}
                    >
                      Create
                    </Link>
                  </div>

                  <FormGroup>
                    <Box
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        borderRadius: "10px",
                        padding: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "20px",
                        // gap: "8px",
                        marginBottom: "18px",
                      }}
                    >
                      <FormControl component="fieldset">
                        {/* <FormLabel component="legend">Is Generative Image</FormLabel> */}
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.values.is_aigenerated === "1"}
                              onChange={handleAIGeneratedChange}
                              name="is_aigenerated"
                            />
                          }
                          label={AppTexts.isAIGeneratedText}
                        />
                      </FormControl>
                    </Box>
                  </FormGroup>

                  {isAIGenerated && (
                    <>
                      <p
                        style={{
                          fontSize: "0.875rem",
                          lineHeight: "1.43",
                          letterSpacing: "0.01071em",
                          color: "rgba(0, 0, 0, 0.6)",
                          maxWidth: "600px",
                          textAlign: "justify",
                          marginBottom: "10px",
                        }}
                      >
                        {AppTexts.AiNoteTerms}
                      </p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isFictional}
                              onChange={(event) =>
                                setIsFictional(event.target.checked)
                              }
                              name="isFictional"
                              sx={{
                                color: AppColors.PrimaryColor,
                                "&.Mui-checked": {
                                  color: AppColors.PrimaryColor,
                                },
                              }}
                            />
                          }
                          label="People and Property are fictional"
                          sx={{
                            ".MuiFormControlLabel-label": {
                              fontWeight: "bold",
                              color: isFictional
                                ? "rgba(0, 0, 0, 0.87)"
                                : "rgba(0, 0, 0, 0.6)",
                              fontSize: 13,
                            },
                          }}
                        />

                        {isFictional && (
                          <p
                            style={{
                              marginTop: "8px",
                              fontSize: "0.875rem",
                              lineHeight: "1.43",
                              letterSpacing: "0.01071em",
                              color: "rgba(0, 0, 0, 0.6)",
                              maxWidth: "600px",
                              textAlign: "justify",
                              marginBottom: "30px",
                            }}
                          >
                            {AppTexts.AiTermsCertify}
                          </p>
                        )}
                      </FormGroup>
                    </>
                  )}

                  {/* Conditional rendering of tags */}
                  {formik.values.isGenerativeImage === "yes" && (
                    <div style={{ marginTop: "10px" }}>
                      {["AI", "Generative Image", "Generative AI"].map(
                        (tag, index) => (
                          <Chip
                            key={index}
                            label={tag}
                            style={{
                              marginRight: "5px",
                              marginBottom: "5px",
                            }}
                          />
                        )
                      )}
                    </div>
                  )}
                  <div className="field-outer">
                    <label>Title</label>
                    <input
                      className="inputField"
                      name="title"
                      type="text"
                      placeholder="Enter Title"
                      {...formik.getFieldProps("title")}
                    />
                    {formik.errors.title && formik.touched.title ? (
                      <div className="validation-error">
                        {formik.errors.title}
                      </div>
                    ) : null}
                  </div>
                  <div className="field-outer">
                    <label>Keyword (Use Comma or enter for next tag)</label>

                    <TagsInput
                      value={[...selected, ...generativeTags]}
                      // onChange={setSelected}
                      onChange={handleTagChange}
                      name="tags"
                      separators={["Enter", ","]}
                      placeHolder="Enter Keyword"
                      renderTag={(tag, index, removeTag) => (
                        <Chip
                          label={tag}
                          onDelete={() => removeTag(index)}
                          style={{ marginRight: "5px", marginBottom: "5px" }}
                        />
                      )}
                    />
                  </div>

                  <div className="field-outer">
                    <label>Description</label>
                    <textarea
                      placeholder="Enter Description"
                      className="inputField"
                      name="description"
                      {...formik.getFieldProps("description")}
                    ></textarea>
                    {formik.errors.description && formik.touched.description ? (
                      <div className="validation-error">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>

                  <div className="field-outer field-outer-radio-wrap ">
                    <label>Select media type:</label>
                    <div className="radio-wrap">
                      <input
                        name="media_type"
                        type="radio"
                        value="editorial"
                        checked={formik.values.media_type === "editorial"}
                        onChange={(e) =>
                          formik.setFieldValue("media_type", e.target.value)
                        }
                      />

                      <label>Editorial</label>
                    </div>

                    <div className="radio-wrap">
                      <input
                        name="media_type"
                        type="radio"
                        value="creative"
                        checked={formik.values.media_type === "creative"}
                        onChange={(e) =>
                          formik.setFieldValue("media_type", e.target.value)
                        }
                      />
                      <label>Creative</label>
                    </div>
                    {formik.errors.media_type && formik.touched.media_type ? (
                      <div className="validation-error">
                        {formik.errors.media_type}
                      </div>
                    ) : null}
                  </div>

                  <div className="field-outer field-outer-action-wrap ">
                    <button
                      type="submit"
                      onClick={() =>
                        formik.setFieldValue("submit_for_approval", 0)
                      }
                      className="btn btn-medium purple-btn"
                    >
                      Save work
                    </button>
                  </div>
                  <div className="field-outer field-outer-action-wrap ">
                    <button
                      type="submit"
                      onClick={() =>
                        formik.setFieldValue("submit_for_approval", 1)
                      }
                      className="btn btn-medium primary-btn"
                    >
                      Submit for Approval
                    </button>
                  </div>
                </>
        
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProductListing;
