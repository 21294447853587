import React from 'react'
import { Navigate } from 'react-router-dom'

import Storage from '../services/local_storage/localStorage';
function ContributorRoutes({children}) {
    const userData=Storage.getUserData()

    return userData.role_label==="Contributor" ? children : <Navigate to="/" />;
}

export default ContributorRoutes