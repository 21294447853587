import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isDownloading: false,
    downloadPercentage: 0,
    uploadFunction: {}
}

const fileDownloadSlice = createSlice({
    name: "fileDownload",
    initialState: initialState,
    reducers: {
        onCall: function (state, action) {

            state.isDownloading = action.payload.isDownloading
            state.downloadPercentage = action.payload.downloadPercentage
            state.uploadFunction = action.payload.uploadFunction
        }
    }
})

export const { onCall } = fileDownloadSlice.actions;

export default fileDownloadSlice.reducer