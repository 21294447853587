import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'


export const HomeConstants = {

  customFooter: {
    // about_picha: [
    //   {
    //     link: "/about",
    //     name: "About Us"
    //   },
    //   {
    //     link: "/contact",
    //     name: "Contact"
    //   },
    //   {
    //     link: "/faqs",
    //     name: "FAQs"
    //   },
    //   {
    //     link: "/become-a-picha-affiliate",
    //     name: "Become a PICHA Affilliate"
    //   },
    //   {
    //     link: "/picha-art",
    //     name: "PICHA Art"
    //   },
    //   {
    //     link: "https://pichastock.com/blog/",
    //     name: "Blog"
    //   },
    //   {
    //     link: "https://visualactivist.me/",
    //     name: "VisualActivist.me",
    //   },
    // ],
    // more_links: [
    //   // {
    //   //   link: "https://pichastock.com/podcast/",
    //   //   name: "Podcast"
    //   // },
    //   {
    //     link: "/careers",
    //     name: "Careers"
    //   },
    //   {
    //     link: "/terms-conditions",
    //     name: "Terms & Conditions"
    //   },
    //   {
    //     link: "/privacy-policy",
    //     name: "Privacy Policy"
    //   },
    //   {
    //     link: "/cookie-policy",
    //     name: "Cookie Policy"
    //   },
    //   {
    //     link: "/login",
    //     name: "Login"
    //   },
    // ],
    // Photographers: [
    //   {
    //     link: "/contributor",
    //     name: "Contributor"
    //   },
    //   {
    //     link: "/becoming-a-picha-contributor",
    //     name: "Become a PICHA Contributor"
    //   },
    //   {
    //     link: "/resources",
    //     name: "Resources"
    //   },
    //   {
    //     link: "/grants",
    //     name: "Grant"
    //   },
    //   {
    //     link: "/awards",
    //     name: "Awards"
    //   },
    // ],
    social_media: [
      {
        link: "https://www.facebook.com/PICHAstock/",
        icon: faFacebookF,
        className: "facebook"
      },
      {
        link: "https://www.instagram.com/pichastock/?hl=en",
        icon: faInstagram,
        className: "instagram"
      },
      {
        link: "https://twitter.com/picha_stock?lang=en",
        icon: faTwitter,
        className: "twitter"
      },
      {
        link: "https://www.youtube.com/channel/UCngDowa8CgI6CVe3m7k31BA",
        icon: faYoutube,
        className: "youtube"
      },
      {
        link: "https://za.linkedin.com/organization-guest/company/picha-stock?challengeId=AQH8DesuF0CcqgAAAXhB2Hkx60bxEiLpKHpAUIuIVOvVJwgUBxVd1kQoo6WSTzairIczs9REstUZybcjPIXokifnRxUNtodTqA&submissionId=e7f35e31-b93a-6d16-c3b2-8a74e2acd16d",
        icon: faLinkedinIn,
        className: "linkedin"
      },
    ]
  },

  CustomNavbarData: [
    // {
    //   link: "/about",
    //   name: "About Us",
    // },
    // {
    //   link: "#",
    //   name: "License",
    //   dropDown: [
    //     {
    //       link: "#",
    //       name: "Free Content Licensing"
    //     },
    //     {
    //       link: "/packages",
    //       name: "Image Pack Membership"
    //     },
    //     {
    //       link: "#",
    //       name: "Free Research"
    //     },
    //     {
    //       link: "#",
    //       name: "Image Brief"
    //     },
    //     {
    //       link: "/image-licensing",
    //       name: "Image Licensing"
    //     }
    //   ]
    // },
    // {
    //   link: "/collections",
    //   name: "Collections",
    // },
    // {
    //   link: "/contact",
    //   name: "Contact",

    // },
    // {
    //   link: "#",
    //   name: "Join",
    //   dropDown: [
    //     {
    //       link: "/register",
    //       name: "Sign up to license visuals"
    //     },
    //     {
    //       link: "/contributor-registration",
    //       name: "Contribute to Picha"
    //     },

    //   ]
    // },
    {
      icon: "#",
      name: "login",
      dropDownMenu: [
        {
          link: '/contributor-dashboard',
          'name': "Dashboard"
        },
        // {
        //   link: "/wishLists",
        //   name: "WishLists"
        // },
        {
          link: "/challenges",
          name: "Challenges"
        },
        {
          link: "#",
          name: "Logout",
        },
      ]
    },
    {
      icon: "#",
      name: "cart"
    },
  ]
}