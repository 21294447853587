import React from 'react';
import { Box, Button as MuiButton } from '@mui/material';

const CustomButton = ({
  children,
  icon,
  backgroundColor = 'transparent',
  borderColor = '#FF0080',
  iconColor = '#FF0080',
  boxShadow = 'none',
  hoverBackgroundColor = 'rgba(255, 0, 128, 0.1)',
  onClick,
  size = 'medium',
  borderRadius = '20px',
  forceLeftAlign = false, 
  height,
  ...props
}) => {
  const paddingSizes = {
    xsm: '4px 6px',
    sm: '6px 8px',
    md: '6px 16px',
    xmd: '6px 22px',
    lg: '6px 36px',
    xlg: '6px 46px',
  };

  const iconWithColor = icon ? React.cloneElement(icon, { style: { color: iconColor } }) : null;

  // Function to determine the style based on props
  const getButtonStyle = () => ({
    padding: paddingSizes[size] || paddingSizes.medium,
    borderRadius,
    background: backgroundColor,
    border: `1px solid ${borderColor}`,
    boxShadow,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: forceLeftAlign ? 'flex-start' : 'center',
    cursor: 'pointer',
    '&:hover': {
      background: hoverBackgroundColor,
    },
    ...(height && { height }), // Apply height only if it's provided
  });

  return (
    <Box
      sx={getButtonStyle}
      onClick={onClick}
    >
      <MuiButton
        startIcon={iconWithColor}
        sx={{
          color: iconColor,
          textTransform: 'none',
          fontSize: '1rem',
          width: '100%', 
          justifyContent: forceLeftAlign ? 'flex-start' : 'inherit', 
          '&:hover': {
            background: 'none',
          },
        }}
        {...props}
      >
        {children}
      </MuiButton>
    </Box>
  );
};

export default CustomButton;
