import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Container,
  Button,
} from "@mui/material";
import { AppColors } from "../constants/AppColors";
import { AppTexts } from "../constants/AppTexts";
import NewsletterSignup from "./NewsletterSignup";
import { Link as RouterLink } from "react-router-dom";

function NewsLetter() {
  return (
    <Container>
      <Grid container spacing={2} mt={5} justifyContent="center">
        <Grid item xs={12} md={10}>
          {/* Replace each top-level Box with Grid item within this Grid container */}
          <Box>
            <Typography variant="h3" component="h2" mb={3}>
              Join our community
            </Typography>
            <Typography variant="h5" my={3}>
              Inspiration awaits: discover hidden gems & photographer spotlights
            </Typography>
            <Typography my={3}>
              Inspired by the power of visuals! Our newsletter features
              captivating stories from talented photographers, showcases hidden
              gem photos within our library, and offers creative prompts to
              spark your next project. Sign up and unlock a world of visual
              inspiration.
            </Typography>
          </Box>

          <NewsletterSignup />

          {/* Each major section can be a Grid item if you want further control over layout */}
          <Box mt={5}>
            <Typography variant="h5" component="h3" mt={5} fontWeight="bold">
              Celebrating Diversity in Community and Culture
            </Typography>
            <Box mt={3} mb={2}>
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/uerpshplpBQ"
                title="PICHA Sizzle reel 2023"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Box>
            <Box textAlign="center" mt={2}>
              <Button
                component={RouterLink}
                to="/collections"
                sx={{
                  textDecoration: "none",
                  color: AppColors.WhiteColor,
                  background: AppColors.PrimaryColor,
                  padding: "10px 20px",
                  borderRadius: "12px",
                  "&:hover": {
                    background: AppColors.PrimaryHoverColor,
                    textDecoration: "none",
                  },
                }}
              >
                {AppTexts.ExploreOurCollection}
              </Button>
           
            </Box>
          </Box>

          <Box className="elevate" mt={5}>
            <Typography variant="h5" component="h3" mb={5}>
              Elevate Your Brand Identity with our beautiful stock visuals
            </Typography>
            <Box mb={3}>
              <img
                src="https://pichastockv2-prod-resize-media.s3.amazonaws.com/assets/pages/news-letter-sign-in/Sign+up+page+1.jpg"
                alt="grants-header"
                style={{ width: "100%" }}
              />
            </Box>
            <Box mb={3}>
              <img
                src="https://pichastockv2-prod-resize-media.s3.amazonaws.com/assets/pages/news-letter-sign-in/Sign+up+page+4.jpg"
                alt="grants-header"
                style={{ width: "100%" }}
              />
            </Box>
          </Box>

          <Paper
            elevation={0}
            sx={{ display: "flex", flexDirection: "row", mb: 4 }}
          >
            <Box flex={1} p={2}>
              <Typography variant="h5" component="h3">
                Unleash Authenticity
              </Typography>
              <Typography>
                Go beyond clichés and stereotypes. Our library features real
                people from all walks of life, cultures, and backgrounds.
              </Typography>
            </Box>
            <Box flex={1}>
              <img
                src="https://pichastockv2-prod-resize-media.s3.amazonaws.com/assets/pages/news-letter-sign-in/sign+up+page+2.gif"
                alt="Animated GIF"
                style={{ width: "100%" }}
              />
            </Box>
          </Paper>

          <Paper
            elevation={0}
            sx={{
              display: "flex",
              // flexDirection: "row",
              mb: 4,
              flexDirection: "row-reverse",
            }}
          >
            <Box flex={1} p={2}>
              <Typography variant="h5" component="h3">
                Tailor-made visual campaigns that perfectly capture your brand
              </Typography>
              <Typography>
                Your brand deserves unique visuals. We craft custom image and
                video campaigns designed to seamlessly integrate with your
                message and resonate with your target audience.
              </Typography>

              <Box className="btn-outer">
                <Box className="contributorBtn-outer">
                  <Button
                    component={RouterLink}
                    to="/image-licensing"
                    sx={{
                      textDecoration: "none",
                      color: AppColors.WhiteColor,
                      background: AppColors.PrimaryColor,
                      padding: "10px 20px",
                      borderRadius: "12px",
                      "&:hover": {
                        background: AppColors.PrimaryHoverColor,
                        textDecoration: "none",
                      },
                    }}
                  >
                    {AppTexts.SendUsArief}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box flex={1}>
              <img
                src="https://pichastockv2-prod-resize-media.s3.amazonaws.com/assets/pages/news-letter-sign-in/sign+up+page+3.gif"
                alt="Animated GIF"
                style={{ width: "100%" }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewsLetter;
