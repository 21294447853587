import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SimpleBackdrop from "../../components/Backdrop";
import Pagination from "../../components/Pagination";
import { errorMessage } from "../../helpers/Message";
import instance from "../../services/apiServices/Api";
import Storage from "../../services/local_storage/localStorage";
import img from "../../assets/imagePlaceholder.jpg";
import NoDataFound from "../../components/NoDataFound";
import { AppTexts } from "../../constants/AppTexts";

function Purchases() {
  const navigate = useNavigate();
  const page = useParams();
  const token = Storage.getToken("token");
  const header = { headers: { Authorization: `Bearer ${token}` } };
  const [purchaseHistoryData, setPurchaseHistoryData] = useState({});
  const [itemsPerPage, setItemsPerPages] = useState();
  const [totalItems, setTotalItems] = useState();
  let [loading, setLoading] = useState(false);
  const currentPage =
    Object.values(page)[0] === "" ? "current_page=1" : Object.values(page)[0];

  useEffect(() => {
    getPurchaseHistory(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  const getPurchaseHistory = () => {
    setLoading(true);
    instance
      .get(`/orders?${currentPage}`, header)
      .then((res) => {
        // let new_arr = res.data.data.items
        // new_arr.reverse();
        setLoading(false);
        setPurchaseHistoryData(res.data.data.items);
        setItemsPerPages(res.data.data.perPage);
        setTotalItems(res.data.data.totalRecords);
      })
      .catch((err) => {
        setLoading(false);
        errorMessage(err.response.data.message);
      });
  };

  return (
    <>
      {loading && <SimpleBackdrop />}
      <h2 className="dashboard-title">Purchase History</h2>
      <div className="main purchase-history-wrap">
        {purchaseHistoryData.length > 0 ? (
          <>
            <div className="purchase-history result-table">
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Thumbnail</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Details</th>
                    <th>Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseHistoryData.length > 0 &&
                    purchaseHistoryData?.map((item, i) => (
                      <>
                        <tr key={item.id}>
                          <td>{item?.id}</td>
                          <td>
                            <img
                              src={
                                item?.order_items[0]?.watermarked_file?.file ??
                                img
                              }
                              alt=""
                              className="m-3-full-width"
                            />
                            {(item?.order_items).length > 1 &&
                              `+ ${(item?.order_items).length - 1}`}
                          </td>
                          <td>
                            {moment(item?.created_at).format("MMMM Do YYYY")}
                          </td>
                          <td>
                            {item?.total > 0 ? "$" + item?.total : "Free"}
                          </td>
                          <td>
                            <button
                              className="btn primary-btn"
                              onClick={() =>
                                navigate("/checkout/purchase-confirmation", {
                                  state: { order_id: item?.id },
                                })
                              }
                            >
                              View Details & Download
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn primary-btn"
                              onClick={() =>
                                navigate("/invoice", {
                                  state: { invoiceData: item },
                                })
                              }
                            >
                              Download
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
            </div>
            <Pagination
              itemsPerPage={itemsPerPage}
              TotalItems={totalItems}
              FocusPage={currentPage.slice(13)}
            />
          </>
        ) : loading ? (
          <h3 className="nodDtaFound">{AppTexts.Loading}.</h3>
        ) : (
          <NoDataFound title={`No purchase history found`} />
        )}
      </div>
    </>
  );
}

export default Purchases;
