import React, { useState } from "react";
import TittleBanner from "../../components/TitleBanner";
import NewsLetter from "../../components/NewsLetter";
import SEO from "../../components/SEO";

function NewsLetterPage() {
  const [Title] = useState("Newsletter");
  const item = {
    title: Title,
    link: "/newsletter-sign-in",
    breadCrumbs_main: Title,
  };

  return (
    <>
      <SEO title="Newsletter" description="Sign up for the Picha Stock Newsletter." canonical="https://www.pichastock.com/newsletter-sign-in" />
      <TittleBanner item={item} />
      <NewsLetter />
    </>
  );
}

export default NewsLetterPage;