import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from "./redux/store";
import { FacebookInit } from './services/FacebookInit';
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';  

// Initialize React Query Client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
FacebookInit();

if (process.env.REACT_APP_BASE_URL === 'backend.pichastock.com/api/') {
    ReactGA.initialize("G-ZVHCN1RQD8");
}

root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </Provider>
);

// Service Worker Registration
// Service Worker Registration
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/service-worker.js') 
            .then((registration) => {
                console.log('Service Worker registered successfully with the scope:', registration.scope);
            })
            .catch((registrationError) => {
                console.log('Service Worker registration failed: ', registrationError);
            });
    });
}


reportWebVitals();
