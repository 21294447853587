import React from 'react'
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';

function Pagination({ itemsPerPage, TotalItems,FocusPage }) {
 
    const navigate=useNavigate()

    const pageCount = Math.ceil(TotalItems / itemsPerPage);

    const handlePageClick = (event) => {
        navigate(`current_page=${event.selected + 1}`)
        // onchange(event.selected + 1)
    };

    return (
        <>
            <div className='customPagination'>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    forcePage={FocusPage-1}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    );
}

export default Pagination