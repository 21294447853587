function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

function formatDateTimeInWords(isoString) {
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    // const timeOptions = { hour: '2-digit', minute: '2-digit' };
    const date = new Date(isoString);
    
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    // const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
  
    // return `${formattedDate} ${formattedTime}`;
    return `${formattedDate}`;
  }

  function formatDateTime(isoString) {
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(isoString);
    
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);

    return formattedDate;
}

  


const utilities = {
    toTitleCase,
    formatDateTime,
    formatDateTimeInWords,
};

export default utilities;
