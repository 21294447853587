import React from 'react'
import { Outlet } from 'react-router-dom'

import CustomFooter from '../components/CustomFooter'
import NavbarCustom from '../components/NavbarCustom'
import ScrollToTop from '../components/ScrollToTop'


function Layout() {
  return (
    <>
      <NavbarCustom />
      <ScrollToTop />
      <main>
        <Outlet />
      </main>
      <CustomFooter />
    </>
  )
}

export default Layout