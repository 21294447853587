
const AppColors = {
    PrimaryColor: '#ff5be5',
    WhiteColor: '#FFFFFF',
    Grey1Color: '#e1e1e1',
    BorderColor: '#dfdfe0',
    OffWhiteF7: '#f7f7f7',
    OffWhite7F: '#7f7f7f',
    BlackColor: '#000000',
    PrimaryHoverColor: '#c33dad',
    PremiumIconColor: '#feb602',
    AIIconColor: '#2955bb',
    RGBA4Color: '#2955bb',
    CardColorGray: '#EAE6E6',
    DarkBlue : '#000267',
    PrimaryBlue: '#0d6efd',
}

export {
    AppColors,
};