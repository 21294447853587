import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onCall } from '../redux/slices/fileSlice';

function UploadProgress() {

    const { downloadPercentage, uploadFunction } = useSelector(item => item.fileDownload)
    const dispatch = useDispatch()

    const CancleUploading = async (e) => {
        e.preventDefault()

        await uploadFunction.abort();
        dispatch(onCall({
            downloadPercentage: 0,
            isDownloading: false
        }))
    }
    return (

        <div className="progress-bar-container">
            <div className="progress-bar-header">
                <div className="progress-bar-column">
                    <p>Video uploading in progress</p>
                    <h3>{`${downloadPercentage}% Complete`}</h3>
                </div>
                <button className="close-btn" onClick={(e) => CancleUploading(e)}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </div>
            <div className="progress progress-striped">
                <div className="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="80"
                    aria-valuemin="0" aria-valuemax="100" style={{ width: `${downloadPercentage}%` }}>
                    {/* <span>{`${downloadPercentage}% Complete`} </span> */}
                </div>
            </div>
        </div>

    )
}

export default UploadProgress