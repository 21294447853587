import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
// import NodataFound from '../assets/NoDataFound.png'

function NoDataFound({ title, subTitle }) {
  return (
    <div className="container mt-3">
      <div className="outer-row mt-5 flex-wrap align-items-center">
        <div className="column-12 ">
          <div className="text-center member-column rounded-corner">
            <h2>{title}</h2>
            <p className="mt-3" hidden={subTitle ? false : true}>
              {subTitle}
            </p>
            <Link hidden={subTitle ? false : true} to='/free-research' className='btn btn-medium mt-3 primary-btn' >
              <FontAwesomeIcon icon={faPaperPlane} /> Send request</Link>
          </div>
        </div>
        {/* <div className="column-medium-6 column-12 ">
             <img src={NodataFound} alt="404" className='full-width'/>
           </div> */}
      </div>
    </div>
  )
}

export default NoDataFound